// @flow
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ApiService from "../../service/ApiService";
import HttpErrorHandler from "../utils/HttpErrorHandler";
import {createDefaultActionBuilder} from "../utils/commonActionBuilder";
import {createDefaultAsyncThunk} from "../utils/commonAsynkThunk";

export const getToken = createAsyncThunk(
  'auth/get-token',
  async ({email, password, callback}, thunkAPI) => {
    let response = null;

    try {
      const baseResource = await ApiService.getBaseResource();

      response = await ApiService.toLink(baseResource.getLink("auth:get-token"), {}, 'POST', {email, password});
    } catch (e) {
      HttpErrorHandler.handleErrors(e.response, thunkAPI);

      return thunkAPI.rejectWithValue(e.resource);
    }

    let responseJson = response.getJson();

    ApiService.setToken(responseJson);

    thunkAPI.dispatch(getUser());

    return responseJson;
  }
)

export const getUser = createDefaultAsyncThunk('auth/get-user', 'auth:user');

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: null,
    loadingCount: 0,
    isLoading: false,
    isViewedApplications: []
  },
  reducers: {
    clearAuthState: (state, action) => {
      state.token = null;
      state.user = null;
      state.loadingCount = 0;
      state.isLoading = false;
      ApiService.removeToken();
    },
    addIsViewedApplication(state, action) {
      if (state.isViewedApplications.indexOf(action.payload.uid) < 0) {
        state.isViewedApplications.push(action.payload.uid);
      }
    },
    removeIsViewedApplication(state, action) {
      if (action.payload.uid) {
        let index = state.isViewedApplications.indexOf(action.payload.uid);

        if (index >= 0) {
          state.isViewedApplications.splice(index, 1);
        }
      }
    }
  },
  extraReducers: (builder) => {
    createDefaultActionBuilder(builder, getToken, 'token');
    createDefaultActionBuilder(builder, getUser, 'user');
  }
})

export const { clearAuthState, addIsViewedApplication, removeIsViewedApplication } = authSlice.actions

export default authSlice.reducer