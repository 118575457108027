import React, {useEffect, useState} from 'react';
import {Button, Spin, Switch, Typography} from "antd";
import './ApplicationBoardPage.scss';
import {useDispatch, useSelector} from "react-redux";
import {getBoard, moveCard, setState} from "../../store/reducers/applicationSlice";
import HalResource from "../../service/HalResource";
import Board from "react-trello-apollo";
import {useHistory} from "react-router-dom";
import CustomCard from "../CustomCard/CustomCard";
import Interval from 'react-interval-rerender'

const { Title } = Typography;

export default function ApplicationBoardPage() {
  const [eventBus, setEventBus] = useState(undefined);
  const [withSpam, setWithSpam] = useState(false);
  const {board, isLoading} = useSelector(state => state?.application ? state.application : {})
  const {isViewedApplications} = useSelector(state => state?.auth ? state.auth : {})
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBoard({
      with_spam: false
    }));
  },[])

  const convertApplicationToCard = (application) => {
    return {
      id: application?.id?.uid,
      description: application?.id?.uid,
      employee: application?.employee,
      tasks: application?.tasks,
      createdAt: application?.created_at,
      title: application?.counterparty?.name,
      isViewedApplications: isViewedApplications,
      metadata: {
        resource: new HalResource(application)
      }
    }
  }

  const data = {
    lanes: []
  };

  board?.forEach(item => {
    data.lanes.push({
      id: item?.id.toString(),
      title: item?.name,
      currentPage: item?.currentPage || 1,
      style: {
        backgroundColor: item?.color,
        color: '#fff',
      },
      cards: item?.items?.map(i => convertApplicationToCard(i))
    })
  })

  const onChangeWithSpamSwitcher = (value) => {
    dispatch(getBoard({
      with_spam: value
    }));
  }

  const loadNextPage = (requestedPage, laneId) => {
    return new Promise((resolve, reject) => {
      dispatch(getBoard({
        notShowingError: true,
        page: requestedPage,
        only_state_id: laneId
      }));

      resolve([]);
    })
  }

  return (
    <div className={'application-board__wrapper'}>
      <Title level={3}>Заявки</Title>

      <Switch className={'application-board__switch'} title={"Показывать спам?"}
              checkedChildren={"Со спамом"} unCheckedChildren={"Без спама"}
              onChange={(value) => {
                setWithSpam(value);
                onChangeWithSpamSwitcher(value);
              }}/>

      <Button className={'application-board__button'} type={'link'} onClick={() => onChangeWithSpamSwitcher(withSpam)}>
        Обновить</Button>

      <Spin spinning={isLoading}>
        <Interval delay={60000}>
          {() => <Board data={data} hideCardDeleteIcon={true} eventBusHandle={setEventBus}
                        components={{
                          Card: CustomCard
                        }}
                        onLaneScroll={(requestedPage, laneId) => loadNextPage(requestedPage, laneId)}
                        onCardClick={(cardId, metadata, laneId) => {
                          history.push('/applications/' + cardId)
                        }}
                        laneSortFunction={(a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()}
                        handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
                          if (sourceLaneId !== targetLaneId) {
                            let resource = cardDetails.metadata.resource;

                            if (!resource.hasLink("action:set-state")) {
                              return;
                            }
                            dispatch(moveCard({
                              fromLaneId: sourceLaneId,
                              toLaneId: targetLaneId,
                              cardId,
                            }))

                            let sourceLaneIndex = null;

                            for (let i = 0; i < board.length; i++) {
                              if (board[i].id === +sourceLaneId) {
                                sourceLaneIndex = sourceLaneId;
                              }
                            }

                            dispatch(setState({
                                resource,
                                method: "POST",
                                body: {
                                  state_id: targetLaneId
                                },
                                onErrorPipe: [
                                  () => moveCard({fromLaneId: targetLaneId, toLaneId: sourceLaneId, cardId}),
                                ],
                                pipe: sourceLaneIndex && board[sourceLaneIndex].items.length < 10 ?
                                  () => getBoard({
                                    notShowingError: true,
                                    page: 1,
                                    only_state_id: sourceLaneId,
                                  }) : null
                              }
                            ));
                          }

                          return false;
                        }}/>
          }
        </Interval>
      </Spin>
    </div>
  )
}