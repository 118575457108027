import React from 'react';
import {Typography} from "antd";
import {CRUDActions, getPageableList} from "../../store/reducers/employeeSlice";
import DataListPage from "../DataListPage/DataListPage";

const { Title } = Typography;

export default function EmployeeListPage() {
  let options = {
    entityName: 'employee',
    actions: {
      CRUDActions,
      getPageableList
    },
    limit: 20,
    canCreate: true,
    deleteText: "Вы действительно хотите удалить сотрудника ",
    deleteField: "surname",
    columns: [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Фамилия',
        dataIndex: 'surname',
        key: 'surname'
      },
      {
        title: 'Отчество',
        dataIndex: 'middlename',
        key: 'middlename'
      }
    ]
  };

  return (
    <>
      <Title level={3}>Сотрудники</Title>

      <DataListPage {...options} />
    </>
  )
}