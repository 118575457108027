export default class HalResource {
  _jsonResource = null;

  constructor(jsonResource) {
    this._jsonResource = jsonResource;
  }

  hasLink(link) {
    return !!this._jsonResource["_links"]?.[link];
  }

  getLink(link) {
    return this._jsonResource["_links"]?.[link];
  }

  getProp(prop) {
    return this._jsonResource?.[prop];
  }

  getEmbedded() {
    return this._jsonResource?.['_embedded'];
  }

  getJson() {
    return this._jsonResource;
  }
}