import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Layout, Menu, Dropdown, Button, notification} from "antd";
import './InnerPage.scss';
import { useLocation } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/utils/commonActions";
import ApiService from "../../service/ApiService";


const { Header, Footer, Content } = Layout;

export default function InnerPage({children}) {
  const location = useLocation();
  const {user} = useSelector(state => state?.auth);
  const dispatch = useDispatch();
  const [baseResource, setBaseResource] = useState(null);
  const isManager = user?.roles.indexOf('ROLE_MANAGER') !== -1;

  useEffect(() => {
    (async () => {
      let baseResource = await ApiService.getBaseResource();
      setBaseResource(baseResource);
    })()
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button type={"link"} onClick={() => {logout(dispatch)}}>
          Выйти
        </Button>
      </Menu.Item>
    </Menu>
  );

  return(
    <Layout>
      <Header style={{justifyContent: "space-between", display: "flex"}}>
        <Menu mode='horizontal' theme="dark" defaultSelectedKeys={[location.pathname?.slice(1)]}>
          {baseResource?.hasLink('application:list') || baseResource?.hasLink('application:board') ? (
            <Menu.SubMenu title="Заявки">
              {baseResource?.hasLink('application:board') ? (
                <Menu.Item key='applicationsBoard'>
                  <Link to={'/applications/board'}>Доска</Link>
                </Menu.Item>
              ) : null}

              {baseResource?.hasLink('application:list') ? (
                <Menu.Item key='applicationsTable'>
                  <Link to={'/applications/table'}>Таблица</Link>
                </Menu.Item>
              ) : null}
            </Menu.SubMenu>
          ) : null}

          {baseResource?.hasLink('employee:list') && !isManager ? (
            <Menu.Item key='employees'>
              <Link to={'/employees'}>Сотрудники</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('company:list') ? (
            <Menu.Item key='companies'>
              <Link to={'/companies'}>Компании</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('counterparty:list') ? (
            <Menu.Item key='counterparties'>
              <Link to={'/counterparties'}>Контрагенты</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('applicationState:list') ? (
            <Menu.Item key='application-states'>
              <Link to={'/application-states'}>Статусы</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('subject:list') ? (
            <Menu.Item key='subjects'>
              <Link to={'/subjects'}>Предметы</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('taskType:list') ? (
            <Menu.Item key='task-types'>
              <Link to={'/task-types'}>Типы работ</Link>
            </Menu.Item>
          ) : null}

          {baseResource?.hasLink('user:list') ? (
            <Menu.Item key='users'>
              <Link to={'/users'}>Пользователи</Link>
            </Menu.Item>
          ) : null}
        </Menu>

        {user && (
          <Dropdown overlay={menu}>
            <span className="ant-btn-link">
              {user?.email} <DownOutlined />
            </span>
          </Dropdown>
        )}
      </Header>
      <Content className='inner-page-container'>
        {children}
      </Content>
      <Footer>SDO CRM v2 &copy;{new Date().getFullYear()}</Footer>
    </Layout>
  )
}