// @flow
import {createSlice} from "@reduxjs/toolkit";
import {createActionAsyncThunk, createCRUDActionsAsyncThunk, createDefaultAsyncThunk} from "../utils/commonAsynkThunk";
import {createCRUDActionBuilder, createDefaultActionBuilder} from "../utils/commonActionBuilder";

const entityName = "applicationState";

export const getPageableList = createDefaultAsyncThunk(`${entityName}:list`, `${entityName}:list`);
export const CRUDActions = createCRUDActionsAsyncThunk(entityName);
export const CRUDNoticesActions = createCRUDActionsAsyncThunk(entityName, '-notices');
export const addNotice = createActionAsyncThunk(entityName, "add-notice");

export const applicationStateSlice = createSlice({
  name: entityName,
  initialState: {
    item: null,
    list: null,
    loadingCount: 0,
    isLoading: false
  },
  reducers: {
    clear(state) {
      state.item = null;
      state.list = null;
      state.listAll = null;
      state.isLoading = false;
      state.loadingCount = 0;
    }
  },
  extraReducers: (builder) => {
    createDefaultActionBuilder(builder, getPageableList, 'list');
    createCRUDActionBuilder(builder, CRUDActions, {
      create: "Статус был успешно создан",
      delete: "Статус был успешно удален",
      update: "Статус был успешно обновлен"
    });
    createCRUDActionBuilder(builder, CRUDNoticesActions, {
      create: "Примечание было успешно создано",
      delete: "Примечание был успешно удалено",
      update: "Примечание был успешно обновлено"
    });
    createDefaultActionBuilder(builder, addNotice);
  }
})

export const {clear} = applicationStateSlice.actions;

export default applicationStateSlice.reducer