import React, {useEffect, useState} from 'react';
import {Popconfirm, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import HalResource from "../../service/HalResource";
import {useHistory} from "react-router-dom";
import CommonAction from "../../utils/commonAction";
import {camelToSnakeCase} from "../../utils/stringHelper";
import ApiService from "../../service/ApiService";

export default function DataListPage({entityName, actions, limit, canCreate, deleteText, deleteField, columns = []}) {
  const {list, isLoading} = useSelector(state => state?.[entityName] ? state[entityName]: {})
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [field, setField] = useState('id');
  const [order, setOrder] = useState('DESC');
  const [baseResource, setBaseResource] = useState(null);

  let dataListResource = null;

  if (list) {
    dataListResource = new HalResource(list);
  }

  const loadDataList = () => {
    dispatch(actions.getPageableList({
      page,
      limit,
      field,
      order
    }))
  }

  useEffect(() => {
    (async () => {
      let baseResource = await ApiService.getBaseResource();

      setBaseResource(baseResource);
    })()

    loadDataList();
  }, [page, field, order])

  const dataDelete = (resource) => {
    dispatch(actions.CRUDActions({
      resource,
      action: CommonAction.DELETE,
      callback: () => {
        loadDataList();
      }
    }));
  }

  let actionsButton = {
    title: 'Действия',
    dataIndex: '',
    key: 'x',
    render: (item) => {
      let resource = new HalResource(item);

      return (
        <>
          {resource.hasLink('action:update') && (
            <Button
              type="primary"
              icon={<EditOutlined/>}
              onClick={() => {
                history.push(`/${camelToSnakeCase(entityName)}/${resource.getProp('id')}`);
              }}
            />
          )}

          {resource.hasLink('action:delete') && (
            <Popconfirm
              placement="topRight"
              title={`${deleteText}${deleteField ? resource.getProp(deleteField) : ""}`}
              onConfirm={() => dataDelete(resource)}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                type="danger"
                icon={<DeleteOutlined/>}
              />
            </Popconfirm>
          )}
        </>
      )
    }
  };

  let resultColumns = [...columns, actionsButton];

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "";

    switch (sorter.order) {
      case "ascend":
        order = "ASC";
        break;
      case "descend":
        order = "DESC";
        break;
      default:
        break;
    }

    let field = order ? sorter.columnKey : "";

    setOrder(order);
    setField(field);
    setPage(pagination.current);
  }

  return (
    <>
      {canCreate && baseResource?.hasLink(entityName + ":create") && (
        <div style={{ marginBottom: 16,textAlign: "right" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {history.push(`/${camelToSnakeCase(entityName)}`)}}
          >Создать</Button>
        </div>
      )}

      <Table
        columns={resultColumns}
        rowKey={record => record.id}
        locale={{
          emptyText: 'Нет данных',
        }}
        dataSource={dataListResource?.getEmbedded()?.items}
        pagination={{
          current: dataListResource?.getProp('page'),
          pageSize: dataListResource?.getProp('limit'),
          total: dataListResource?.getProp('total')
        }}
        loading={!!isLoading}
        onChange={handleTableChange}
      />
    </>
  )
}