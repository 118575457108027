// @flow
import {createAsyncThunk} from "@reduxjs/toolkit";
import ApiService from "../../service/ApiService";
import HttpErrorHandler from "./HttpErrorHandler";
import CommonAction from "../../utils/commonAction";

export const createDefaultAsyncThunk = (typePrefix: string, linkName: string) => {
  return createAsyncThunk(
    typePrefix,
    async (thunkArgs, thunkAPI) => {
      let response = null;

      try {
        const baseResource = await ApiService.getBaseResource();

        response = await ApiService.toLink(baseResource.getLink(linkName), thunkArgs);

        if (response) {
          return response.getJson();
        }
      } catch (e) {
        HttpErrorHandler.handleErrors(e.response, thunkAPI);

        return thunkAPI.rejectWithValue(e.resource);
      }
    }
  )
}

export const createCRUDActionsAsyncThunk = (entityName: string, suffix: string) => {
  return createAsyncThunk(
    `${entityName}/crud-actions${suffix}`,
    async (thunkArgs, thunkAPI) => {
      let {resource, action, params, body, callback, pipe} = thunkArgs;
      let method, link;
      let response = null;
      let baseResource = null

      try {
        switch (action) {
          case CommonAction.UPDATE:
            method = 'PUT';
            link = resource.getLink('action:update');
            break;

          case CommonAction.DELETE:
            method = 'DELETE';
            link = resource.getLink('action:delete');
            break;

          case CommonAction.CREATE: {
            method = 'POST';

            baseResource = await ApiService.getBaseResource();

            link = baseResource.getLink(`${entityName}:create`);
            break;
          }
          case CommonAction.READ: {
            method = 'GET';
            baseResource = await ApiService.getBaseResource();

            link = baseResource.getLink(`${entityName}:item`);

            if (!params) {
              params = {};
            }

            params.id = thunkArgs.id;
            break;
          }
          default:
            break;
        }

        if (!link) {
          throw new Error("Операция запрещена");
        }

        response = await ApiService.toLink(link, params, method, body);

        if (pipe) {
          if (typeof pipe === 'object'){
            pipe.forEach(func => {
              let result = func(response, baseResource);

              if (result) {
                thunkAPI.dispatch(result);
              }
            })
          } else {
            thunkAPI.dispatch(pipe(response, baseResource));
          }
        }
      } catch (e) {
        console.log(e);
        HttpErrorHandler.handleErrors(e.response, thunkAPI);

        return thunkAPI.rejectWithValue(e.resource);
      }

      if (callback) {
        callback();
      }

      let result = {
        action
      }

      if (response) {
        result.data = response.getJson();
      }

      return result;
    }
  )
}

export const createActionAsyncThunk = (entityName: string, action: string) => {
  return createAsyncThunk(
    `${entityName}/action-${action}`,
    async (thunkArgs, thunkAPI) => {
      let {resource, params, body, callback, method, pipe, onErrorPipe} = thunkArgs;
      let link;
      let response = null;

      if (!method) {
        method = 'POST';
      }

      try {
        link = resource.getLink(`action:${action}`);

        if (!link) {
          throw new Error("Операция запрещена");
        }

        response = await ApiService.toLink(link, params, method, body);

        if (pipe) {
          if (typeof pipe === 'object'){
            pipe.forEach(func => {
              thunkAPI.dispatch(func());
            })
          } else {
            thunkAPI.dispatch(pipe());
          }
        }
      } catch (e) {
        console.log(e);

        if (onErrorPipe) {
          if (typeof onErrorPipe === 'object'){
            onErrorPipe.forEach(func => {
              thunkAPI.dispatch(func());
            })
          } else {
            thunkAPI.dispatch(onErrorPipe());
          }
        }

        HttpErrorHandler.handleErrors(e.response, thunkAPI);

        return thunkAPI.rejectWithValue(e.resource);
      }

      if (callback) {
        callback();
      }

      let result = {
        action
      }

      if (response) {
        result.data = response.getJson();
      }

      return result;
    }
  )
}

export const createPropAsyncThunk = (entityName: string, prop: string) => {
  return createAsyncThunk(
    `${entityName}/prop-${prop}`,
    async (thunkArgs, thunkAPI) => {
      let {resource, params, body, callback, method, pipe} = thunkArgs;
      let link;
      let response = null;

      if (!method) {
        method = 'GET';
      }

      try {
        link = resource.getLink(`prop:${prop}`);

        if (!link) {
          throw new Error("Операция запрещена");
        }

        response = await ApiService.toLink(link, params, method, body);

        if (pipe) {
          if (typeof pipe === 'object'){
            pipe.forEach(func => {
              thunkAPI.dispatch(func(response));
            })
          } else {
            thunkAPI.dispatch(pipe(response));
          }
        }
      } catch (e) {
        console.log(e);
        HttpErrorHandler.handleErrors(e.response, thunkAPI);

        return thunkAPI.rejectWithValue(e.resource);
      }

      if (callback) {
        callback();
      }

      return response?.getJson();
    }
  )
}