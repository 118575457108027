import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import 'antd/dist/antd.css';
import './App.scss';
import React from "react";
import InnerPage from "../InnerPage/InnerPage";
import LoginForm from "../LoginForm/LoginForm";
import PrivateRoute from "../PrivateRouter/PrivateRouter";
import UserListPage from "../UserListPage/UserListPage";
import ApplicationListPage from "../ApplicationListPage/ApplicationListPage";
import UserPage from "../UserPage/UserPage";
import EmployeeListPage from "../EmployeeListPage/EmployeeListPage";
import EmployeePage from "../EmployeePage/EmployeePage";
import ApplicationStateListPage from "../ApplicationStateListPage/ApplicationStateListPage";
import ApplicationStatePage from "../ApplicationStatePage/ApplicationStatePage";
import CounterpartyPage from "../CounterpartyPage/CounterpartyPage";
import CounterpartyListPage from "../CounterpartyListPage/CounterpartyListPage";
import CompanyPage from "../CompanyPage/CompanyPage";
import CompanyListPage from "../CompanyListPage/CompanyListPage";
import ApplicationPage from "../ApplicationPage/ApplicationPage";
import ApplicationBoardPage from "../ApplicationBoardPage/ApplicationBoardPage";
import SubjectListPage from "../SubjectListPage/SubjectListPage";
import TaskTypeListPage from "../TaskTypeListPage/TaskTypeListPage";
import SubjectPage from "../SubjectPage/SubjectPage";
import TaskTypePage from "../TaskTypePage/TaskTypePage";
import {useSelector} from "react-redux";
import InitComponent from "../InitComponent/InitComponent";

function App() {
  const {token} = useSelector(state => state?.auth);

  const router = <Router>
    <Switch>
      <Route exact path="/">
        <Redirect to="/applications/board" />
      </Route>
      <Route exact path="/applications">
        <Redirect to="/applications/board" />
      </Route>

      <PrivateRoute exact path="/applications/board" component={() => <InnerPage><ApplicationBoardPage /></InnerPage>}/>
      <PrivateRoute exact path="/applications/table" component={() => <InnerPage><ApplicationListPage /></InnerPage>}/>
      <PrivateRoute path="/applications/:id" component={() => <InnerPage><ApplicationPage /></InnerPage>}/>

      <PrivateRoute exact path="/users" component={() => <InnerPage><UserListPage /></InnerPage>}/>
      <PrivateRoute exact path="/user" component={() => <InnerPage><UserPage /></InnerPage>}/>
      <PrivateRoute exact path="/user/:id" component={() => <InnerPage><UserPage /></InnerPage>}/>

      <PrivateRoute exact path="/employees" component={() => <InnerPage><EmployeeListPage /></InnerPage>}/>
      <PrivateRoute exact path="/employee" component={() => <InnerPage><EmployeePage /></InnerPage>}/>
      <PrivateRoute exact path="/employee/:id" component={() => <InnerPage><EmployeePage /></InnerPage>}/>

      <PrivateRoute exact path="/application-states" component={() => <InnerPage><ApplicationStateListPage /></InnerPage>}/>
      <PrivateRoute exact path="/application-state" component={() => <InnerPage><ApplicationStatePage /></InnerPage>}/>
      <PrivateRoute exact path="/application-state/:id" component={() => <InnerPage><ApplicationStatePage /></InnerPage>}/>

      <PrivateRoute exact path="/counterparties" component={() => <InnerPage><CounterpartyListPage /></InnerPage>}/>
      <PrivateRoute exact path="/counterparty" component={() => <InnerPage><CounterpartyPage /></InnerPage>}/>
      <PrivateRoute exact path="/counterparty/:id" component={() => <InnerPage><CounterpartyPage /></InnerPage>}/>

      <PrivateRoute exact path="/companies" component={() => <InnerPage><CompanyListPage /></InnerPage>}/>
      <PrivateRoute exact path="/company" component={() => <InnerPage><CompanyPage /></InnerPage>}/>
      <PrivateRoute exact path="/company/:id" component={() => <InnerPage><CompanyPage /></InnerPage>}/>

      <PrivateRoute exact path="/subjects" component={() => <InnerPage><SubjectListPage /></InnerPage>}/>
      <PrivateRoute exact path="/subject" component={() => <InnerPage><SubjectPage /></InnerPage>}/>
      <PrivateRoute exact path="/subject/:id" component={() => <InnerPage><SubjectPage /></InnerPage>}/>

      <PrivateRoute exact path="/task-types" component={() => <InnerPage><TaskTypeListPage /></InnerPage>}/>
      <PrivateRoute exact path="/task-type" component={() => <InnerPage><TaskTypePage /></InnerPage>}/>
      <PrivateRoute exact path="/task-type/:id" component={() => <InnerPage><TaskTypePage /></InnerPage>}/>

      <Route path="/login">
        <LoginForm />
      </Route>
    </Switch>
  </Router>

  return (
    token ? (
      <InitComponent>
        {router}
      </InitComponent>
    ) : router
  );
}

export default App;
