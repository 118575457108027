import {message} from "antd";
import {logout} from "./commonActions";

export default class HttpErrorHandler {
  static handleErrors(response, thunkAPI) {
    switch (response?.status) {
      case 401:
        logout(thunkAPI.dispatch);
        message.error('Срок действия токена авторизации истек')

        break;

      default:
        break;
    }
  }
}