import React, {useEffect, useState} from 'react';
import {Popconfirm, Table, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import HalResource from "../../service/HalResource";
import {useHistory} from "react-router-dom";
import CommonAction from "../../utils/commonAction";
import {CRUDActions, getPageableList} from "../../store/reducers/companySlice";

const { Title } = Typography;

export default function CompanyListPage() {
  const {list, isLoading} = useSelector(state => state?.company ? state.company: {})
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [field, setField] = useState('id');
  const [order, setOrder] = useState('DESC');

  const detailLink = "/company";

  let listResource = null;

  if (list) {
    listResource = new HalResource(list);
  }

  const loadDataList = () => {
    dispatch(getPageableList({
      page,
      limit: 20,
      field,
      order
    }))
  }

  useEffect(() => {
    loadDataList();
  }, [page, field, order])

  const dataDelete = (resource) => {
    dispatch(CRUDActions({
      resource,
      action: CommonAction.DELETE,
      callback: () => {
        loadDataList();
      }
    }));
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Действия',
      dataIndex: '',
      key: 'x',
      render: (item) => {
        let resource = new HalResource(item);

        return (
          <>
            {resource.hasLink('action:update') && (
              <Button
                type="primary"
                icon={<EditOutlined/>}
                onClick={() => {
                  history.push(detailLink + "/" + resource.getProp('id'));
                }}
              />
            )}

            {resource.hasLink('action:delete') && (
              <Popconfirm
                placement="topRight"
                title={`Вы действительно хотите удалить компанию ${item.name}`}
                onConfirm={() => dataDelete(resource)}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  type="danger"
                  icon={<DeleteOutlined/>}
                />
              </Popconfirm>
            )}
          </>
        )
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "";

    switch (sorter.order) {
      case "ascend":
        order = "ASC";
        break;
      case "descend":
        order = "DESC";
        break;
      default:
        break;
    }

    let field = order ? sorter.columnKey : "";

    setOrder(order);
    setField(field);
    setPage(pagination.current);
  }

  return (
    <>
      <Title level={3}>Компании</Title>

      <div style={{ marginBottom: 16,textAlign: "right" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {history.push(detailLink)}}
        >Создать</Button>
      </div>

      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={listResource?.getEmbedded()?.items}
        locale={{
          emptyText: 'Нет данных',
        }}
        pagination={{
          current: listResource?.getProp('page'),
          pageSize: listResource?.getProp('limit'),
          total: listResource?.getProp('total')
        }}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  )
}