import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Input, Select, Space, Spin, Switch, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import HalResource from "../../service/HalResource";
import CommonAction from "../../utils/commonAction";
import {CRUDActions} from "../../store/reducers/applicationStateSlice";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import ChannelsEditableTable from "../ChannelsEditableTable/ChannelsEditableTable";
import ApplicationStateNoticeEditableTable
  from "../ApplicationStateNoticeEditableTable/ApplicationStateNoticeEditableTable";

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export default function ApplicationStatePage() {
  let {id} = useParams();

  const {item, isLoading} = useSelector(state => state?.applicationState);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  let dataResource = null;

  if (item) {
    dataResource = new HalResource(item);
  }

  if (!isSaving && id) {
    form?.setFieldsValue({
      name: dataResource?.getProp('name'),
      color: dataResource?.getProp('color'),
      is_active: dataResource?.getProp('is_active'),
      description: dataResource?.getProp('description'),
      notices: dataResource?.getProp('notices') || [],
      order_number: dataResource?.getProp('order_number'),
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(CRUDActions({
        action: CommonAction.READ,
        id
      }))
    }
  }, [id])

  const onFinish = (values) => {
    setIsSaving(true);

    let action = {
      body: values,
      callback: () => {
        setIsSaving(false);
        history.push("/application-states");
      }
    }

    if (id) {
      action.action = CommonAction.UPDATE;
      action.resource = new HalResource(item);
    } else {
      action.action = CommonAction.CREATE;
    }

    dispatch(CRUDActions(action));
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={"/application-states"}>Статусы</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{!id ? "Создание" : "Редактирование"} статуса</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3} style={{marginTop: ".7em"}}>{!id ? "Создание" : "Редактирование"} статуса</Title>

      <Spin spinning={isLoading}>
        <div style={{maxWidth: 700, margin: "0 auto"}}>
          <Form {...layout} form={form} name="control-ref" onFinish={onFinish}>
            <Form.Item
              name="name"
              label="Название"
              rules={[
                {
                  required: true,
                  message: 'Укажите название',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name="color"
              label="Цвет"
              rules={[
                {
                  required: true,
                  message: 'Выберите цвет',
                },
              ]}
            >
              <Input type={'color'}/>
            </Form.Item>

            <Form.Item
              name="description"
              label="Описание"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
              name="is_active"
              label="Активен"
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>

            <Form.Item
              name="order_number"
              label="Порядковый номер"
            >
              <Input type={"number"}/>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? "Загрузка" : (
                  !id ? "Создать" : "Сохранить"
                )}
              </Button>
            </Form.Item>
          </Form>

          {id && (
            <ApplicationStateNoticeEditableTable applicationStateResource={dataResource}
                                                 notices={dataResource?.getProp('notices')}
                                                 applicationStateId={id}/>
          )}
        </div>
      </Spin>
    </>
  )
}