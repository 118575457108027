export const IS_SPAM_STATE_ID = 10;

export const APPLICATIONS_BOARD_PAGE_SIZE = 10;

export const MercureHubEvents = {
  HEARTBEAT: 'HEARTBEAT',

  APPLICATION_DESCRIPTION_UPDATED: 'APPLICATION_DESCRIPTION_UPDATED',
  APPLICATION_STATE_UPDATED: 'APPLICATION_STATE_UPDATED',
  APPLICATION_NOTICE_UPDATED: 'APPLICATION_NOTICE_UPDATED',
  APPLICATION_EMPLOYEE_UPDATED: 'APPLICATION_EMPLOYEE_UPDATED',
  APPLICATION_MESSAGE_ADDED: 'APPLICATION_MESSAGE_ADDED',
  APPLICATION_TASKS_UPDATED: 'APPLICATION_TASKS_UPDATED',
  APPLICATION_ADDED: 'APPLICATION_ADDED',
  APPLICATION_DELETED: 'APPLICATION_DELETED',

  APPLICATION_STATES_UPDATED: 'APPLICATION_STATES_UPDATED',
  EMPLOYEES_UPDATED: 'EMPLOYEES_UPDATED',
  TASK_TYPES_UPDATED: 'TASK_TYPES_UPDATED',
  SUBJECTS_UPDATED: 'SUBJECTS_UPDATED'
}

export const API_SERVER_HOST = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8000';
export const MERCURE_SERVER_HOST = process.env.REACT_APP_MERCURE_URL ? process.env.REACT_APP_MERCURE_URL : 'http://localhost:9000';