import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Input, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import HalResource from "../../service/HalResource";
import {CRUDActions} from "../../store/reducers/userSlice";
import CommonAction from "../../utils/commonAction";

const { Title } = Typography;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export default function UserPage() {
  let { id } = useParams();

  const {item, isLoading} = useSelector(state => state?.user)
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  let dataResource = null;

  if (item) {
    dataResource = new HalResource(item);
  }

  if (!isSaving && id) {
    form?.setFieldsValue({
      email: dataResource?.getProp('email'),
      roles: dataResource?.getProp('roles')[0],
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(CRUDActions({
        action: CommonAction.READ,
        id
      }))
    }
  }, [id])

  const onFinish = (values) => {
    setIsSaving(true);

    let action = {
      body: values,
      callback: () => {
        setIsSaving(false);
        history.push("/users");
      }
    }

    if (id) {
      action.action = CommonAction.UPDATE;
      action.resource = new HalResource(item);
    } else {
      action.action = CommonAction.CREATE;
    }

    dispatch(CRUDActions(action));
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={"/users"}>Пользователи</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{!id ? "Создание" : "Редактирование"} пользователя</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3} style={{marginTop: ".7em"}}>{!id ? "Создание" : "Редактирование"} пользователя</Title>

      <div style={{maxWidth: 700,margin: "0 auto"}}>
        <Spin spinning={isLoading}>
          <Form {...layout} form={form} name="control-ref" onFinish={onFinish} >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  pattern: /.*@.*/,
                  message: 'Укажите email',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="roles"
              label="Роль"
              rules={[
                {
                  required: true,
                  message: 'Выберите роль',
                },
              ]}
            >
              <Select
                placeholder="Выберите роль"
                allowClear
              >
                <Option value="ROLE_MANAGER">Менеджер</Option>
                <Option value="ROLE_SENIOR_MANAGER">Руководитель менеджеров</Option>
                <Option value="ROLE_ADMIN">Администратор</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              label="Пароль"
              rules={[
                {
                  min: 6,
                  message: 'Некорректный пароль (Минимум 6 символов)',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? "Загрузка" : (
                  !id ? "Создать" : "Сохранить"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  )
}