import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Input, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import HalResource from "../../service/HalResource";
import CommonAction from "../../utils/commonAction";
import {CRUDActions, getUsersWithoutEmployee} from "../../store/reducers/employeeSlice";

const { Title } = Typography;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export default function EmployeePage() {
  let { id } = useParams();

  const {isLoading, users, item} = useSelector(state => state?.employee);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  let dataResource = null;
  let user = null;

  if (item) {
    dataResource = new HalResource(item);
    user = dataResource.getProp('user');
  }

  if (!isSaving && id) {
    form?.setFieldsValue({
      name: dataResource?.getProp('name'),
      middlename: dataResource?.getProp('middlename'),
      surname: dataResource?.getProp('surname'),
      user_id: user?.id,
    });
  }

  useEffect(() => {
    dispatch(getUsersWithoutEmployee())

    if (id) {
      dispatch(CRUDActions({
        action: CommonAction.READ,
        id
      }))
    }
  }, [id])

  const onFinish = (values) => {
    setIsSaving(true);

    let action = {
      body: values,
      callback: () => {
        setIsSaving(false);
        history.push("/employees");
      }
    }

    if (id) {
      action.action = CommonAction.UPDATE;
      action.resource = new HalResource(item);
    } else {
      action.action = CommonAction.CREATE;
    }

    dispatch(CRUDActions(action));
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={"/employees"}>Сотрудники</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{!id ? "Создание" : "Редактирование"} сотрудника</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3} style={{marginTop: ".7em"}}>{!id ? "Создание" : "Редактирование"} сотрудника</Title>

      <div style={{maxWidth: 700,margin: "0 auto"}}>
        <Spin spinning={isLoading}>
          <Form {...layout} form={form} name="control-ref" onFinish={onFinish} >
            <Form.Item
              name="name"
              label="Имя"
              rules={[
                {
                  required: true,
                  message: 'Укажите имя',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name="surname"
              label="Фамилия"
              rules={[
                {
                  required: true,
                  message: 'Укажите фамилию',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name="middlename"
              label="Отчество"
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name="user_id"
              label="Пользователь"
            >
              <Select
                placeholder="Выберите пользователя"
                allowClear
              >
                {user && (
                  <Option value={user.id}>{user.email}</Option>
                )}
                {users && users?.length && users.map(item =>
                  (
                    <Option key={item.id} value={item.id}>{item.email}</Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? "Загрузка" : (
                  !id ? "Создать" : "Сохранить"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  )
}