import React, {useEffect, useState} from 'react';
import './ApplicationListPage.scss';
import {Form, Row, Col, Input, Popconfirm, Table, Tag, Typography, Button, Divider, Select, Switch, Tabs} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {EditOutlined, DeleteOutlined, WarningOutlined} from '@ant-design/icons';
import HalResource from "../../service/HalResource";
import {Link, useHistory} from "react-router-dom";
import CommonAction from "../../utils/commonAction";
import {CRUDActions, getAllStates, getPageableList, setState} from "../../store/reducers/applicationSlice";
import moment from "moment";
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'moment/locale/ru';
import {IS_SPAM_STATE_ID} from "../../utils/constants";

const { RangePicker } = DatePicker;
const { Title } = Typography;

moment.locale('ru');

export default function ApplicationStatePage() {
  const {list, isLoading, states} = useSelector(state => state?.application ? state.application: {})
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [field, setField] = useState('createdAt');
  const [order, setOrder] = useState('DESC');
  const [params, setParams] = useState([]);

  const detailLink = "/application-state";

  let listResource = null;

  if (list) {
    listResource = new HalResource(list);
  }

  const loadDataList = () => {
    if (params?.date_range?.[0]) {
      params.date_range_from = params.date_range[0].format("YYYY-MM-DD HH:mm:ss");
    }

    if (params?.date_range?.[1]) {
      params.date_range_to = params.date_range[1].format("YYYY-MM-DD HH:mm:ss");
    }

    dispatch(getPageableList({
      page,
      limit: 20,
      field,
      order,
      ...params
    }))
  }

  useEffect(() => {
    dispatch(getAllStates({
      is_active_only: true
    }));

    loadDataList();
  }, [page, field, order, params])

  const dataDelete = (resource) => {
    dispatch(CRUDActions({
      resource,
      action: CommonAction.DELETE,
      callback: () => {
        loadDataList();
      }
    }));
  }

  let columns = [
    {
      title: 'Номер заявки',
      dataIndex: 'id',
      key: 'id',
      render: item => <Link to={"/applications/" + item.uid}>{item.uid}</Link>
    },
    {
      title: 'Контрагент',
      dataIndex: 'counterparty',
      key: 'counterparty',
      render: item => item.name
    },
    {
      title: 'Ответственный',
      dataIndex: 'employee',
      key: 'employee',
      render: item => {
        if (item) {
          return <Tag color={'blue'}>{item.name} {item.surname}</Tag>;
        } else {
          return <Tag color={'red'}>Не назначено</Tag>
        }
      }
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      render: item => {
        return <Tag color={item.color}>{item.name}</Tag>;
      }
    },
    {
      title: 'Создан',
      dataIndex: 'created_at',
      key: 'created_at',
      render: item => moment(item).utc().format("DD-MM-YYYY HH:mm:ss")
    },
    {
      title: 'Действия',
      dataIndex: '',
      key: 'x',
      render: (item) => {
        let resource = new HalResource(item);

        return (
          <>
            <Button
              type="primary"
              icon={<EditOutlined/>}
              onClick={() => {
                history.push(detailLink + "/" + resource.getProp('id'));
              }}
            />

            {resource.hasLink('action:delete') && (
              <Popconfirm
                placement="topRight"
                title={`Вы действительно хотите удалить заявку ${item.name}`}
                onConfirm={() => dataDelete(resource)}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  title="Удалить"
                  type="danger"
                  icon={<DeleteOutlined/>}
                />
              </Popconfirm>
            )}

            {resource.hasLink('action:set-state') && (
              <Popconfirm
                placement="topRight"
                title={`Вы действительно хотите отправить в спам заявку ${item?.id?.uid}`}
                onConfirm={() => {
                  dispatch(setState({resource, body: {
                      state_id: IS_SPAM_STATE_ID
                    }}));
                  loadDataList();
                }}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  title="Это спам?"
                  icon={<WarningOutlined/>}
                />
              </Popconfirm>
            )}
          </>
        )
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "";

    switch (sorter.order) {
      case "ascend":
        order = "ASC";
        break;
      case "descend":
        order = "DESC";
        break;
      default:
        break;
    }

    let field = order ? sorter.columnKey : "";

    setOrder(order);
    setField(field);
    setPage(pagination.current);
  }

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setParams(values);
  };

  return (
    <>
      <Title level={3}>Заявки</Title>

      <Form
        style={{margin: "30px 0"}}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >

        <Row gutter={24}>
          <Col span={11} >
            <Form.Item
              name='id'
              label='Номер заявки'
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={13} >
            <Form.Item
              name='counterparty'
              label='Контрагент'
            >
              <Input.Group compact>
                <Form.Item
                  name='counterparty_name'
                >
                  <Input placeholder="Имя" />
                </Form.Item>

                <Form.Item
                  name='counterparty_email'
                  rules={[
                    {
                      pattern: /.*@.*/,
                      message: 'Укажите корректный email',
                    }
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name='counterparty_phone'
                >
                  <Input placeholder="Телефон" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={11} >
            <Form.Item
              name='application_state'
              label='Статус'
            >
              {states && (
                <Select placeholder="Выберите статус">
                  {states.map(item => (
                    <Select.Option key={item.id} value={item.id} style={{backgroundColor: item.color, color: 'white'}}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={13} >
            <Form.Item
              name='employee'
              label='Ответственный'
            >
              <Input.Group compact>
                <Form.Item
                  name='employee_surname'
                >
                  <Input placeholder="Фамилия" />
                </Form.Item>

                <Form.Item
                  name='employee_name'
                >
                  <Input placeholder="Имя" />
                </Form.Item>

                <Form.Item
                  name='employee_middlename'
                >
                  <Input placeholder="Отчество" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={11} >
            <Form.Item
              name='date_range'
              label='Период'
            >
              <RangePicker showTime locale={locale}/>
            </Form.Item>
          </Col>

          <Col span={13}>
            <Form.Item
              name='with_spam'
              label='Показывать спам?'
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col
            span={24}
            style={{
              textAlign: 'right',
            }}
          >
            <Button type="primary" htmlType="submit">
              Поиск
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => {
                form.resetFields();
                setParams([]);
                setPage(1);
              }}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
      </Form>

        <Table
        columns={columns}
        rowKey={record => record.id.uid}
        locale={{
          emptyText: 'Нет данных',
        }}
        dataSource={listResource?.getEmbedded()?.items}
        pagination={{
          current: listResource?.getProp('page'),
          pageSize: listResource?.getProp('limit'),
          total: listResource?.getProp('total')
        }}
        loading={isLoading}
        onChange={handleTableChange}
        />
    </>
  )
}