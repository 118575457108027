// @flow
import UriTemplate from 'uri-templates';
import HalResource from "./HalResource";
import {Mutex} from 'async-mutex';
import {clearAuthState} from "../store/reducers/authSlice";
import {API_SERVER_HOST} from "../utils/constants";

export default class ApiService {
  static mutexForGettingToken = new Mutex();
  static mutexForGettingBaseResource = new Mutex();
  static isTokenExpired = false;
  static isInit = false;
  static welcomeURL = API_SERVER_HOST + '/api/greetings';
  static baseResource = null;
  static isUpdateNeeded = false;
  static defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  static token = null;
  static dispatch = null;

  static async getBaseResource(forceReload = false): HalResource {
    if (!this.isInit) {
      await this.#loadTokenFromStorage();

      this.isInit = true;
    }

    await this.mutexForGettingBaseResource.runExclusive(async () => {
      if (!this.baseResource || forceReload || this.isUpdateNeeded) {
          this.baseResource = await this.#fetch();
          this.isUpdateNeeded = false;
      }
    });

    return this.baseResource;
  }

  static setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  static getToken() {
    return this.token;
  }

  static setToken(token) {
    localStorage.setItem('token', JSON.stringify(token))

    this.token = token;
    this.isUpdateNeeded = true;
  }

  static async #loadTokenFromStorage() {
    let value = localStorage.getItem('token')

    if (value) {
      try {
        this.token = JSON.parse(value);
      } catch (e) {

      }
    }
  }

  static removeToken() {
    this.setToken(null)
    this.isUpdateNeeded = true;
  }

  static async #fetch({url = this.welcomeURL, method = 'GET', headers = {}, body = null} = {}) {
    let {response, resource} = await this.#tryLoadResource(url, method, headers, body);

    if (resource) {
      resource = new HalResource(resource);
    }

    if ((url === this.welcomeURL && !resource?.hasLink('auth:user')
      && resource?.hasLink('auth:refresh-token')) || (response.status === 401 && this.token)) {
      this.isTokenExpired = true;

      await this.#refreshToken(url === this.welcomeURL ? resource : this.baseResource);

      let secondTry = await this.#tryLoadResource(url, method, headers, body);

      response = secondTry.response

      if (resource) {
        resource = new HalResource(secondTry.resource);
      }
    }

    if (!/2\d{2}/.test(response.status)) {
      if (url === this.welcomeURL) {
        this.isUpdateNeeded = true;
      }

      let error = new Error(`${response.status} ${response.statusText}`);

      error.response = response;

      if (resource) {
        error.resource = resource;
      }

      throw error;
    }

    if (resource) {
      return resource;
    }
  }

  static async #refreshToken(resource) {
    await this.mutexForGettingToken.runExclusive(async () => {
      if (this.isTokenExpired) {
        if (!resource?.hasLink('auth:refresh-token')) {
          throw new Error("Действие запрещено")
        }

        try {
          let tokenResource = await ApiService.toLink(resource.getLink('auth:refresh-token'), {},
            'POST', {
              refresh_token: this.token?.refresh_token
            });

          this.setToken(tokenResource.getJson());
        } catch (e) {
          this.dispatch?.(clearAuthState())
          this.removeToken();
        }
      }

      this.isTokenExpired = false
    });
  }

  static async #tryLoadResource(url, method, headers, body) {
    let normalizedHeaders = {
      ...this.defaultHeaders, ...headers,
      ...(this.token && {"Authorization": `Bearer ${this.token?.token}`})
    };
    let normalizedBody = this.#normalizeBody(body, normalizedHeaders['Content-Type'])

    let response = await fetch(url, {
      method: method,
      headers: normalizedHeaders,
      body: normalizedBody
    })

    let resource = null;

    try {
      resource = await response.json();
    } catch (e) {

    }

    return {
      resource,
      response
    }
  }

  static #normalizeBody(body, contentType) {
    if (body) {
      switch (contentType) {
        case 'application/json':
          body = JSON.stringify(body);

          break;
        case 'multipart/form-data':
          const data = new FormData();
          for (let key in body) {
            data.append(key, body[key]);
          }

          body = data;
          break;

        default:
          break;
      }
    }

    return body;
  }

  static async toLink(link, params = {}, method = 'GET', body = null, headers = {}): HalResource {
    let url = null;

    if (link.templated) {
      url = new UriTemplate(link.href).fill(params);
    } else {
      url = link.href;
    }

    return this.#fetch({url, method, headers, body});
  }
}