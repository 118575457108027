import {Button, Form, Input, Popconfirm, Select, Space, Switch, Table, Typography} from "antd";
import React, {useState} from "react";
import {useDispatch} from "react-redux";

import CommonAction from "../../utils/commonAction";
import HalResource from "../../service/HalResource";
import {addChannel, CRUDActions, CRUDChannelActions} from "../../store/reducers/companySlice";
import {CRUDNoticesActions} from "../../store/reducers/applicationStateSlice";

const { Title } = Typography;

const EditableCell = ({
                        editing,
                        dataIndex,
                        title,
                        inputType,
                        record,
                        index,
                        children,
                        ...restProps
                      }) => {
  let inputNode = null;

  switch (dataIndex) {
    case 'type':
      inputNode = <Select placeholder="Выберите тип">
        <Select.Option value={'EMAIL'}>
          EMAIL
        </Select.Option>
      </Select>
      break;

    case 'name':
      inputNode = <Input placeholder={'Название'}/>
      break;

    case 'credentials':
      inputNode = <Input.TextArea placeholder={'Параметры доступ'}/>
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!dataIndex,
              message: `Укажите ${title}`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

export default function ChannelsEditableTable ({companyResource, channels, companyId}) {
  const [formForCreate] = Form.useForm();
  const [formForUpdate] = Form.useForm();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  let channelList = channels?.map(item => {
    let record = Object.assign({}, item);

    record.credentials = JSON.stringify(record.credentials);

    return record;
  })

  const edit = (record) => {
    formForUpdate.setFieldsValue({
      name: record?.name,
      type: record?.type,
      credentials: record?.credentials
    });
    setEditingKey(record?.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (resource) => {
    try {
      const row = await formForUpdate.validateFields();

      dispatch(CRUDChannelActions({
        action: CommonAction.UPDATE,
        resource: resource,
        body: {
          id: resource?.getProp('id'),
          name: row?.name,
          type: row?.type,
          credentials: row?.credentials
        },
        pipe: () => CRUDActions({
          action: CommonAction.READ,
          id: companyId,
          callback: () => setEditingKey('')
        })
      }))
    } catch (errInfo) {

    }
  };

  const removeChannel = async (resource) => {
    dispatch(CRUDChannelActions({
      action: CommonAction.DELETE,
      resource: resource,
      pipe: () => CRUDActions({
        action: CommonAction.READ,
        id: companyId
      })
    }));
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '20%',
      editable: true
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      width: '20%',
      editable: true,
      render: (item) => {
         return item;
      }
    },
    {
      title: 'Параметры доступа',
      dataIndex: 'credentials',
      width: '20%',
      editable: true
    },
    {
      title: 'Активен',
      dataIndex: '',
      width: '20%',
      editable: true,
      render: (item) => {
        return <Switch title={"Активен?"}
                       checkedChildren={"Активно"} unCheckedChildren={"Не активно"}
                       defaultChecked={item?.is_active} onChange={(checked, event) => {
          dispatch(CRUDChannelActions({
            action: CommonAction.UPDATE,
            resource: new HalResource(item),
            body: {
              is_active: checked
            },
            pipe: () => CRUDActions({
              action: CommonAction.READ,
              id: companyResource.getProp('id')
            })
          }))
        }}/>
      }
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        const resource = new HalResource(record);

        return editable ? (
          <span>
            <Button
              type={'link'}
              onClick={() => save(resource)}
              style={{
                marginRight: 8,
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              Сохранить
            </Button>

            <Popconfirm title="Уверены что хотите отменить изменения?" onConfirm={cancel}
                        cancelText={'Нет'} okText={'Да'}>
              <Button type={'link'} style={{
                paddingLeft: 0,
                paddingRight: 0
              }}>
                  Отмена
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            {resource?.getLink('action:update') && (
              <Button
                type={'link'}
                onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                  padding: 0,
                  height: 'auto'
                }}
                disabled={editingKey !== ''}
              >
                Редактировать
              </Button>
            )}

            {resource?.getLink('action:delete') && (
              <Popconfirm title="Уверены что хотите удалить канал связи?" onConfirm={() => removeChannel(resource)}
                          cancelText={'Нет'} okText={'Да'}>
                <Button
                  type={'link'}
                  style={{
                    marginRight: 8,
                    padding: 0,
                    height: 'auto'
                  }}
                  disabled={editingKey !== ''}
                >
                  Удалить
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onFinish = (values) => {
    dispatch(addChannel({
      resource: companyResource,
      body: values,
      callback: () => {
        formForCreate.setFieldsValue({
          name: '',
          type: '',
          credentials: ''
        });
      },
      pipe: () => CRUDActions({
        action: CommonAction.READ,
        id: companyId
      }),
    }))
  }

  return (
    <>
      <Form
        style={{marginBottom: "30px"}}
        form={formForCreate}
        layout={'vertical'}
        onFinish={onFinish}
      >
        <Title level={4}>Каналы связи</Title>
        <Space style={{display: 'flex'}}
               align="flex-start" size={8} className={'space-full'}>
          <Form.Item
            name='name'
            rules={[
              {
                required: true,
                message: `Укажите название канала`,
              },
            ]}
          >
            <Input placeholder={'Название канала'}/>
          </Form.Item>

          <Form.Item
            name='type'
            rules={[
              {
                required: true,
                message: `Укажите тип канала связи`,
              },
            ]}
          >
            <Select placeholder="Тип канала связи">
                <Select.Option value={'EMAIL'}>EMAIL</Select.Option>
              </Select>
          </Form.Item>

          <Form.Item
            name={'credentials'}
          >
            <Input.TextArea placeholder={'Параметры доступа'}/>
          </Form.Item>
        </Space>

        <div className="send-button-wrapper">
          <Button type="primary" htmlType="submit">Создать</Button>
        </div>

      </Form>

      <Form
        form={formForUpdate}
        onFinish={onFinish}
      >
        <Table
          rowClassName={(record) => record?.is_done ? 'row-success' : ''}
          locale={{
            emptyText: 'Нет данных',
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey={(record) => record?.id}
          bordered
          dataSource={channelList}
          columns={mergedColumns}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Form>
    </>
  );
};