import React, {useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Button} from "antd";
import './MessageEditor.scss';
import { EditorState } from 'draft-js';
import {stateToHTML} from "draft-js-export-html";

export default function MessageEditor({onSendMessage}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="message-editor-toolbar"
        wrapperClassName="message-editor-wrapper"
        editorClassName="message-editor-editor"
        onEditorStateChange={setEditorState}
      />

      <div className={'send-button-wrapper'} style={{marginTop: '15px'}}>
        <Button type="primary" htmlType="submit" onClick={() => {
          onSendMessage(stateToHTML(editorState?.getCurrentContent()));
          setEditorState(EditorState.createEmpty());
        }}>
          Отправить сообщение
        </Button>
      </div>
    </>
  )
}