// @flow
import {message as messageModal} from "antd";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import {AsyncThunkAction} from "@reduxjs/toolkit";
import CommonAction from "../../utils/commonAction";

export const createDefaultActionBuilder = (builder: ActionReducerMapBuilder, action: AsyncThunkAction, fieldName: string) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (fieldName) {
      state[fieldName] = action.payload;
    }

    action?.meta?.arg?.onSuccess?.();

    state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
    state.isLoading = state.loadingCount > 0;
  })
    .addCase(action.pending, (state) => {
      state.loadingCount = state.loadingCount + 1;
      state.isLoading = state.loadingCount > 0;
    })
    .addCase(action.rejected, (state, action) => {
      state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
      state.isLoading = state.loadingCount > 0;

      action?.meta?.arg?.onError?.();

      let message = action.payload?.exception?.message || action.payload?.messages;

      if (message) {
        messageModal.error(message);
      } else {
        messageModal.error("Произошла ошибка");
      }
    })
}

export const createCRUDActionBuilder = (builder: ActionReducerMapBuilder, action: AsyncThunkAction, messages, field = 'item', setExtraFields = []) => {
  builder.addCase(action.fulfilled, (state, action) => {
    state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
    state.isLoading = state.loadingCount > 0;

    switch (action.payload?.action) {
      case CommonAction.READ:
        state[field] = action.payload?.data;

        if (setExtraFields.length > 0) {
          setExtraFields.forEach(item => state[item] = action.payload?.data?.[item]);
        }

        break;
      case CommonAction.CREATE:
        messageModal.success(messages.create);

        break;
      case CommonAction.DELETE:
        messageModal.success(messages.delete);

        break;
      case CommonAction.UPDATE:
        messageModal.success(messages.update);

        break;
      default:
    }
  })
    .addCase(action.pending, (state, action) => {
      state.loadingCount = state.loadingCount + 1;
      state.isLoading = state.loadingCount > 0;
    })
    .addCase(action.rejected, (state, action) => {
      state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
      state.isLoading = state.loadingCount > 0;

      let message = action.payload?.exception?.message //|| action.payload?.messages[0];

      if (message) {
        messageModal.error(message);
      } else {
        messageModal.error("Произошла ошибка");
      }
    })
}