import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './CustomCard.scss';
import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer
} from 'react-trello/dist/styles/Base'
import InlineInput from 'react-trello/dist/widgets/InlineInput'
import Tag from 'react-trello/dist/components/Card/Tag'
import {Badge, Tag as AntTag} from 'antd';
import moment from "moment";
import {formatEmployeeName} from "../../utils/stringHelper";

function CustomCard({style, tagStyle, onClick, onChange, className, id,
                      title, employee, tasks, createdAt, description, tags, cardDraggable, editable, t, isViewedApplications}) {

  const updateCard = (card) => {
    onChange({...card, id})
  }

  let tasksIsDone = tasks?.reduce((sum, item) => sum + (item?.is_done ? 1 : 0), 0)

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={onClick}
      style={style}
      className={className}
    >
      <CardHeader>
        <CardTitle draggable={cardDraggable}>
          {editable ? <InlineInput value={title} border placeholder={t('placeholder.title')} resize='vertical' onSave={(value) => updateCard({title: value})} /> : title}
        </CardTitle>
        <CardRightContent>
          {isViewedApplications?.indexOf(id) < 0 ? (
            <Badge count={'!'} size={'small'} offset={[15, 0]}/>
          ) : null}
        </CardRightContent>
      </CardHeader>
      <div className={'custom-card__info-line'}>
        <span className={'custom-card__item-title'}>Ответств.:</span>
          {employee && employee.surname ? (
            <AntTag color={'blue'}>
              {formatEmployeeName(employee)}
            </AntTag>
          ) : (
            <AntTag color={'red'}>Не назначено</AntTag>
          )}
      </div>
      <div className={'custom-card__info-line'}>
        <span className={'custom-card__item-title'}>Задачи:</span>
        <AntTag color={tasksIsDone === tasks.length ? 'green' : 'red'}>{tasksIsDone}/{tasks.length}</AntTag>
      </div>
      <div className={'custom-card__info-line'}>
        <span className={'custom-card__item-title'}>Создана:</span>
        <span className={'custom-card__item-text'}>{moment(createdAt).fromNow()}</span>
      </div>
      <Detail>
        {editable ? <InlineInput value={description} border placeholder={t('placeholder.description')} resize='vertical' onSave={(value) => updateCard({description: value})} /> : description}
      </Detail>
      {tags && tags.length> 0 && (
        <Footer>
          {tags.map(tag => (
            <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
          ))}
        </Footer>
      )}
    </MovableCardWrapper>
  )
}

CustomCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  employee: PropTypes.object,
  tasks: PropTypes.array,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
}

CustomCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  employee: {},
  tasks: [],
  tags: [],
  className: ''
}

export default CustomCard