import {Button, Collapse, Form, Input, Popconfirm, Select, Space, Spin, Switch, Table, Typography} from "antd";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addTask, CRUDTaskActions, getTasks, taskSetIsDone} from "../../store/reducers/applicationSlice";

import CommonAction from "../../utils/commonAction";
import HalResource from "../../service/HalResource";
import {formatEmployeeName} from "../../utils/stringHelper";

const { Title } = Typography;

const EditableCell = ({
                        editing,
                        dataIndex,
                        title,
                        inputType,
                        record,
                        index,
                        children,
                        ...restProps
                      }) => {
  const {employees, subjects, taskTypes} = useSelector(state => state?.application);
  let inputNode = null;

  switch (dataIndex) {
    case 'employee':
      if (employees) {
        inputNode = <Select placeholder="Выберите ответственного">
          {employees.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {`${item.name?.substr(0, 1)}. ${item.middlename?.substr(0, 1)}. ${item.surname}`}
            </Select.Option>
          ))}
        </Select>
      }
      break;

    case 'subject':
      if (subjects) {
        inputNode = <Select placeholder="Предмет">
          {subjects.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      }
      break;

    case 'type':
      if (taskTypes) {
        inputNode = <Select placeholder="Тип работы">
            {taskTypes.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
      }
      break;

    case 'description':
      inputNode = <Input.TextArea placeholder={'Описание'}/>
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: !!dataIndex,
              message: `Укажите ${title}`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function TasksEditableTable ({applicationResource}) {
  const [formForCreate] = Form.useForm();
  const [formForUpdate] = Form.useForm();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const {employees, tasks, subjects, taskTypes} = useSelector(state => state?.application);
  const {user} = useSelector(state => state?.auth);
  const isEditing = (record) => record.id === editingKey;
  const isOwnerOrAdmin = !(user.roles.indexOf('ROLE_MANAGER') !== -1 && user.employee.id !== applicationResource?.getProp('employee')?.id);

  const edit = (record) => {
    formForUpdate.setFieldsValue({
      employee: record?.employee?.id,
      subject: record?.subject?.id,
      type: record?.type?.id,
      description: record?.description
    });
    setEditingKey(record?.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (resource) => {
    try {
      const row = await formForUpdate.validateFields();

      dispatch(CRUDTaskActions({
        action: CommonAction.UPDATE,
        resource: resource,
        body: {
          id: resource?.getProp('id'),
          employee_id: row?.employee,
          subject_id: row?.subject,
          task_type_id: row?.type,
          description: row?.description
        },
        pipe: () => getTasks({resource: applicationResource, callback: () => setEditingKey('')})
      }))
    } catch (errInfo) {

    }
  };

  const removeTask = async (resource) => {
    dispatch(CRUDTaskActions({
      action: CommonAction.DELETE,
      resource: resource,
      pipe: () => getTasks({resource: applicationResource})
    }));
  }

  const columns = [
    {
      title: 'Ответственный',
      dataIndex: 'employee',
      width: '20%',
      editable: true,
      render: (item) => {
         return `${formatEmployeeName(item)}`;
      }
    },
    {
      title: 'Предмет',
      dataIndex: 'subject',
      width: '20%',
      editable: true,
      render: (item) => {
        return item?.name;
      }
    },
    {
      title: 'Тип работы',
      dataIndex: 'type',
      width: '20%',
      editable: true,
      render: (item) => {
        return item?.name;
      }
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      width: '20%',
      editable: true,
      render: (item) => {
        return <Collapse accordion ghost={true}>
          <Collapse.Panel header={'Показать/Скрыть'}>
            {item}
          </Collapse.Panel>
        </Collapse>
      }
    },
    {
      title: 'Готовность',
      dataIndex: '',
      width: '20%',
      editable: true,
      render: (item) => {
        return <Switch title={"Выполнена"}
                       checkedChildren={"Выполнена"} unCheckedChildren={"Не выполнена"}
                       checked={item?.is_done} onChange={(checked, event) => {
                          dispatch(taskSetIsDone({
                            resource: new HalResource(item),
                            body: {
                              is_done: checked
                            },
                            pipe: () => getTasks({resource: applicationResource})
                          }))
                       }} disabled={user.roles.indexOf('ROLE_MANAGER') !== -1
        && user.employee.id !== item.employee.id && user.employee.id !== applicationResource?.getProp('employee')?.id}/>
      }
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        const resource = new HalResource(record);

        return editable ? (
          <span>
            <Button
              type={'link'}
              onClick={() => save(resource)}
              style={{
                marginRight: 8,
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              Сохранить
            </Button>

            <Popconfirm title="Уверены что хотите отменить изменения?" onConfirm={cancel}
                        cancelText={'Нет'} okText={'Да'}>
              <Button type={'link'} style={{
                paddingLeft: 0,
                paddingRight: 0
              }}>
                  Отмена
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            {resource?.getLink('action:update') && isOwnerOrAdmin && (
              <Button
                type={'link'}
                onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                  padding: 0,
                  height: 'auto'
                }}
                disabled={editingKey !== ''}
              >
                Редактировать
              </Button>
            )}

            {resource?.getLink('action:delete') && isOwnerOrAdmin && (
              <Popconfirm title="Уверены что хотите удалить задачу?" onConfirm={() => removeTask(resource)}
                          cancelText={'Нет'} okText={'Да'}>
                <Button
                  type={'link'}
                  style={{
                    marginRight: 8,
                    padding: 0,
                    height: 'auto'
                  }}
                  disabled={editingKey !== ''}
                >
                  Удалить
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onFinish = (values) => {
    dispatch(addTask({
      resource: applicationResource,
      body: values,
      callback: () => {
        formForCreate.setFieldsValue({
          employee: '',
          subject: '',
          type: '',
          description: ''
        });
      },
      pipe: () => getTasks({resource: applicationResource}),
    }))
  }

  return (
    <>
      <Form
        style={{marginBottom: "30px"}}
        form={formForCreate}
        layout={'vertical'}
        onFinish={onFinish}
      >
        <Title level={4}>Задачи</Title>
        {applicationResource?.hasLink('action:add-task') && isOwnerOrAdmin && (
          <>
          <Space style={{display: 'flex'}}
                 align="flex-start" size={8} className={'space-full'}>
            <Form.Item
              name='employee_id'
              rules={[
                {
                  required: true,
                  message: `Укажите ответственного`,
                },
              ]}
            >
              {employees && (
                <Select placeholder="Ответственный">
                  {employees.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {formatEmployeeName(item)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              name='subject_id'
              rules={[
                {
                  required: true,
                  message: `Укажите предмет`,
                },
              ]}
            >
              {subjects && (
                <Select placeholder="Предмет">
                  {subjects.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              name='task_type_id'
              rules={[
                {
                  required: true,
                  message: `Укажите тип работы`,
                },
              ]}
            >
              {taskTypes && (
                <Select placeholder="Тип работы">
                  {taskTypes.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              name={'description'}
            >
              <Input.TextArea placeholder={'Описание'}/>
            </Form.Item>
          </Space>

          <div className="send-button-wrapper">
            <Button type="primary" htmlType="submit">Создать</Button>
          </div>
        </>
      )}

      </Form>

      <Form
        form={formForUpdate}
        onFinish={onFinish}
      >
        <Table
          rowClassName={(record) => record?.is_done ? 'row-success' : ''}
          locale={{
            emptyText: 'Нет данных',
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey={(record) => record?.id}
          bordered
          dataSource={tasks}
          columns={mergedColumns}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Form>
    </>
  );
};