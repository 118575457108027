import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Form, Input, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import HalResource from "../../service/HalResource";
import CommonAction from "../../utils/commonAction";
import {CRUDActions} from "../../store/reducers/subjectSlice";

const { Title } = Typography;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

export default function SubjectPage() {
  let { id } = useParams();

  const {item, isLoading} = useSelector(state => state?.subject);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  let dataResource = null;

  if (item) {
    dataResource = new HalResource(item);
  }

  if (!isSaving && id) {
    form?.setFieldsValue({
      name: dataResource?.getProp('name'),
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(CRUDActions({
        action: CommonAction.READ,
        id
      }))
    }
  }, [id])

  const onFinish = (values) => {
    setIsSaving(true);

    let action = {
      body: values,
      callback: () => {
        setIsSaving(false);
        history.push("/subjects");
      }
    }

    if (id) {
      action.action = CommonAction.UPDATE;
      action.resource = new HalResource(item);
    } else {
      action.action = CommonAction.CREATE;
    }

    dispatch(CRUDActions(action));
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={"/subjects"}>Предметы</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{!id ? "Создание" : "Редактирование"} предмета</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3} style={{marginTop: ".7em"}}>{!id ? "Создание" : "Редактирование"} предмета</Title>

      <div style={{maxWidth: 700,margin: "0 auto"}}>
        <Spin spinning={isLoading}>
          <Form {...layout} form={form} name="control-ref" onFinish={onFinish} >
            <Form.Item
              name="name"
              label="Название"
              rules={[
                {
                  required: true,
                  message: 'Укажите название',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? "Загрузка" : (
                  !id ? "Создать" : "Сохранить"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  )
}