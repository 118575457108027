// @flow
import {createSlice} from "@reduxjs/toolkit";
import {
  createActionAsyncThunk,
  createCRUDActionsAsyncThunk,
  createDefaultAsyncThunk,
  createPropAsyncThunk
} from "../utils/commonAsynkThunk";
import {createCRUDActionBuilder, createDefaultActionBuilder} from "../utils/commonActionBuilder";
import {message as messageModal} from "antd";
import {merge, mergeApplications} from "../../utils/array";
import {APPLICATIONS_BOARD_PAGE_SIZE} from "../../utils/constants";
import SoundMessage from "../../utils/soundMessage";
import {removeIsViewedApplication} from "./authSlice";

const entityName = "application";

export const getPageableList = createDefaultAsyncThunk(`${entityName}:list`, `${entityName}:list`);
export const getAllStates = createDefaultAsyncThunk(`${entityName}:state-list`, "applicationState:list");
export const getAllSubjects = createDefaultAsyncThunk(`${entityName}:subject-list`, "subject:list");
export const getAllTaskTypes = createDefaultAsyncThunk(`${entityName}:task-type-list`, "taskType:list");
export const getAllEmployees = createDefaultAsyncThunk(`${entityName}:employee-list`, "employee:list");
export const getBoard = createDefaultAsyncThunk(`${entityName}:board`, `${entityName}:board`);
export const getMessages = createPropAsyncThunk(`${entityName}:messages`, 'messages');
export const getTasks = createPropAsyncThunk(`${entityName}:tasks`, 'tasks');
export const getBoardHeader = createDefaultAsyncThunk(`${entityName}:board`, `${entityName}:board`);
export const isSpam = createActionAsyncThunk(entityName, "is-spam");
export const setState = createActionAsyncThunk(entityName, "set-state");
export const setNotice = createActionAsyncThunk(entityName, "set-notice");
export const setEmployee = createActionAsyncThunk(entityName, "set-employee");
export const setDescription = createActionAsyncThunk(entityName, "set-description");
export const addMessage = createActionAsyncThunk(entityName, "add-message");
export const addTask = createActionAsyncThunk(entityName, "add-task");
export const taskSetIsDone = createActionAsyncThunk("task", "set-is-done");
export const CRUDActions = createCRUDActionsAsyncThunk(entityName);
export const CRUDTaskActions = createCRUDActionsAsyncThunk(entityName, '-task');

export const applicationSlice = createSlice({
  name: entityName,
  initialState: {
    item: null,
    list: null,
    messages: [],
    employees: [],
    tasks: [],
    subjects: [],
    taskTypes: [],
    board: [],
    states: [],
    loadingCount: 0,
    isLoading: false
  },
  reducers: {
    clear(state) {
      state.item = null;
      state.list = null;
      state.board = [];
      state.messages = [];
      state.employees = [];
      state.tasks = [];
      state.subjects = [];
      state.taskTypes = [];
      state.states = [];
      state.isLoading = false;
      state.loadingCount = 0;
    },
    moveCard(state, action) {
      let {fromLaneId, toLaneId, cardId} = action.payload

      moveCardInBoard(state, fromLaneId, toLaneId, cardId);
    },
    changeDescription(state, action) {
      let {applicationId, description} = action.payload;

      if (state.item?.id?.uid === applicationId) {
        state.item.description = description;
      }
    },
    changeState(state, action) {
      let {applicationId, newState, oldStateId} = action.payload;

      if (state.item?.id?.uid === applicationId) {
        state.item.state = newState || {};
      }

      let listIndex = findApplicationInListByUid(state, applicationId);

      if (listIndex >= 0) {
        if (state.list) {
          state.list._embedded.items[listIndex].state = newState;
        }
      }

      moveCardInBoard(state, oldStateId, newState?.id, applicationId);
      updateCurrentPageInBoard(state);
    },
    changeNotice(state, action) {
      let {applicationId, notice} = action.payload;

      if (state.item?.id?.uid === applicationId) {
        state.item.notice = notice || {};
      }
    },
    changeEmployee(state, action) {
      let {application, newEmployee, user} = action.payload;

      if (state.item?.id?.uid === application?.id?.uid) {
        state.item.employee = newEmployee || {};
      }

      let listIndex = findApplicationInListByUid(state, application?.id?.uid);

      if (listIndex >= 0) {
        state.list._embedded.items[listIndex].employee = newEmployee;
      }

      let boardIndexes = findApplicationInBoardByUid(state, application?.id?.uid);

      if (boardIndexes) {
        state.board[boardIndexes.lineIndex].items[boardIndexes.cardIndex].employee = newEmployee;
      }

      if (user.roles?.find(item => item === 'ROLE_MANAGER')) {
        if (application?._links?.['action:delete']) {
          delete application._links['action:delete'];
        }

        if (application?.employee?.user?.id === user?.id || application?.tasks?.find(item => item?.employee?.user?.id === user?.id)) {
          addApplicationToListAndBoard(state, application);
          updateCurrentPageInBoard(state);
          SoundMessage.play('incomingMessage');
        } else {
          removeApplicationFromListAndBoard(state, application?.id?.uid)
          updateCurrentPageInBoard(state);
        }
      }
    },
    updateTaskTypes(state, action) {
      state.taskTypes = action.payload.taskTypes;
    },
    updateSubjects(state, action) {
      state.subjects = action.payload.subjects;
    },
    updateEmployees(state, action) {
      state.employees = action.payload.employees;
    },
    updateStates(state, action) {
      state.states = action.payload.applicationStates;
    },
    updateTasks(state, action) {
      let {application, tasks, user} = action.payload;
      let isFound = false;

      if (state.item?.id?.uid === application?.id?.uid) {
        isFound = true;
        state.tasks = tasks;
      }

      let boardIndexes = findApplicationInBoardByUid(state, application?.id?.uid);

      if (boardIndexes) {
        isFound = true;
        state.board[boardIndexes.lineIndex].items[boardIndexes.cardIndex].tasks = tasks;
      }

      if (user.roles?.find(item => item === 'ROLE_MANAGER')) {
        if (application?._links?.['action:delete']) {
          delete application?._links?.['action:delete'];
        }

        if (application?.employee?.user?.id === user?.id || tasks?.find(item => item?.employee?.user?.id === user?.id)) {
          isFound = true;
          addApplicationToListAndBoard(state, application);
          updateCurrentPageInBoard(state);
        } else {
          isFound = false;
          removeApplicationFromListAndBoard(state, application?.id?.uid)
          updateCurrentPageInBoard(state);
        }
      }

      if (isFound) {
        SoundMessage.play('incomingMessage');
      }
    },
    updateMessage(state, action) {
      let {applicationId, message} = action.payload;
      let isFound = false;

      if (state.item?.id?.uid === applicationId) {
        if (!state.messages?.find(item => item?.id?.uid === message?.id?.uid)) {
          state.messages.push(message);
          window?.scrollToBottom();
          isFound = true;
        }
      }

      let listIndex = findApplicationInListByUid(state, applicationId);

      if (listIndex >= 0) {
        isFound = true;
      }

      let boardIndexes = findApplicationInBoardByUid(state, applicationId);

      if (boardIndexes) {
        isFound = true;
      }

      console.log(listIndex, boardIndexes);

      if (isFound) {
        SoundMessage.play('incomingMessage');
      }
    },
    removeApplication(state, action) {
      let {applicationId} = action.payload;

      removeApplicationFromListAndBoard(state, applicationId);
      updateCurrentPageInBoard(state);
    },
    addApplication(state, action) {
      let {application, user} = action.payload;

      if (!user.roles?.find(item => item === 'ROLE_MANAGER')) {
        SoundMessage.play('incomingMessage');
        addApplicationToListAndBoard(state, application);
        updateCurrentPageInBoard(state);
      }
    }
  },
  extraReducers: (builder) => {
    createDefaultActionBuilder(builder, isSpam);
    createDefaultActionBuilder(builder, setState);
    createDefaultActionBuilder(builder, setNotice);
    createDefaultActionBuilder(builder, setEmployee);
    createDefaultActionBuilder(builder, setDescription);
    createDefaultActionBuilder(builder, addMessage);
    createDefaultActionBuilder(builder, addTask);
    createDefaultActionBuilder(builder, taskSetIsDone);
    createDefaultActionBuilder(builder, getMessages, 'messages');
    createDefaultActionBuilder(builder, getTasks, 'tasks');
    createDefaultActionBuilder(builder, getPageableList, 'list');
    createDefaultActionBuilder(builder, getAllStates, 'states');
    createDefaultActionBuilder(builder, getAllSubjects, 'subjects');
    createDefaultActionBuilder(builder, getAllTaskTypes, 'taskTypes');
    createDefaultActionBuilder(builder, getAllEmployees, 'employees');
    createCRUDActionBuilder(builder, CRUDActions, {
      create: "Заявка была успешно создан",
      delete: "Заявка была успешно удален",
      update: "Заявка была успешно обновлен"
    }, 'item', ['tasks']);
    createCRUDActionBuilder(builder, CRUDTaskActions, {
      create: "Задача была успешно создана",
      delete: "Задача была успешно удалена",
      update: "Задача была успешно обновлена"
    });

    builder.addCase(getBoard.fulfilled, (state, action) => {;
      if (action?.meta?.arg?.only_header) {
        action?.meta?.arg?.onSuccess?.();
        action.payload?.forEach(itemFromDb => {
          let isFound = false;

          state.board.forEach((item, index, array) => {
            if (itemFromDb?.id === item.id) {
              array[index].name = itemFromDb.name;
              array[index].color = itemFromDb.color;
              array[index].orderNumber = itemFromDb.orderNumber;
              array[index].total = itemFromDb.total;

              isFound = true;
            }
          })

          if (!isFound) {
            state.board.push(itemFromDb).sort((a, b) => a.orderNumber - b.orderNumber);
          }
        })
      } else if (action?.meta?.arg?.only_state_id) {
        state.board.forEach((item, index, array) => {
          if (action?.meta?.arg?.only_state_id === item?.id.toString()) {
            array[index].currentPage = action.payload?.page;
            array[index].items = mergeApplications(array[index].items, action.payload?._embedded?.items)
          }
        })

        action?.meta?.arg?.onSuccess?.(action.payload?._embedded?.items);
      } else {
        state.board = action.payload;
      }

      state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
      state.isLoading = state.loadingCount > 0;
    })
      .addCase(getBoard.pending, (state) => {
        state.loadingCount = state.loadingCount + 1;
        state.isLoading = state.loadingCount > 0;
      })
      .addCase(getBoard.rejected, (state, action) => {
        state.loadingCount = state.loadingCount <= 0 ? 0 : state.loadingCount - 1;
        state.isLoading = state.loadingCount > 0;

        let message = action.payload?.exception?.message || action.payload?.messages;

        if (!action?.meta?.arg?.notShowingError) {
          if (message) {
            messageModal.error(message);
          } else {
            messageModal.error("Произошла ошибка");
          }
        }
      })
  }
})

function addApplicationToListAndBoard(state, application) {
  let listIndex = findApplicationInListByUid(state, application?.id?.uid);

  if (listIndex < 0) {
    state.list?._embedded?.items?.unshift(application);
  }

  let isFound = false;
  let laneIdIndex = -1;

  for (let j = 0; j < state.board.length; j++) {
    if (state.board[j]?.id === +application?.state?.id) {
      laneIdIndex = j;
    }

    for (let k = 0; k < state.board[j].items.length; k++) {
      if (state.board[j].items[k]?.id?.uid === application?.id?.uid){
        isFound = true;
        break;
      }
    }
  }

  if (!isFound) {
    state.board[laneIdIndex]?.items?.unshift(application);
  }
}

function removeApplicationFromListAndBoard(state, applicationId) {
  let listIndex = findApplicationInListByUid(state, applicationId);

  if (listIndex >= 0) {
    state.list?._embedded?.items?.splice(listIndex, 1);
  }

  let boardIndexes = findApplicationInBoardByUid(state, applicationId);

  if (boardIndexes) {
    state.board[boardIndexes.lineIndex].items.splice(boardIndexes.cardIndex, 1);
  }
}

function findApplicationInListByUid(state, applicationId) {
  let resultIndex = -1;

  if (state.list) {
    for (let i = 0; i < state.list._embedded?.items?.length; i++) {
      if (state.list._embedded?.items[i]?.id?.uid === applicationId) {
        resultIndex = i;
        break;
      }
    }
  }

  return resultIndex;
}

function findApplicationInBoardByUid(state, applicationId) {
  let resultIndexes = null;

  for (let j = 0; j < state.board.length; j++) {
    for (let k = 0; k < state.board[j].items.length; k++) {
      if (state.board[j].items[k]?.id?.uid === applicationId){
        resultIndexes = {lineIndex: j, cardIndex: k}
        break;
      }
    }
  }

  return resultIndexes;
}

function moveCardInBoard(state, fromLaneId, toLaneId, cardId) {
  if (state.board.length) {
    let fromLaneIndex = -1;
    let fromCardIndex = -1;
    let toLaneIndex = -1;

    for (let i = 0; i < state.board.length; i++) {
      if (state.board[i].id === +fromLaneId) {
        fromLaneIndex = i;

        for (let j = 0; j < state.board[i].items.length; j++) {
          if (state.board[i].items[j]?.id?.uid === cardId) {
            fromCardIndex = j;

            break;
          }
        }
      }

      if (state.board[i].id === +toLaneId) {
        toLaneIndex = i;
      }

      if (fromLaneIndex >= 0 && toLaneIndex >= 0) {
        break;
      }
    }

    if (fromLaneIndex >= 0 && toLaneIndex >= 0 && fromCardIndex >= 0) {
      let card = state.board[fromLaneIndex].items[fromCardIndex];

      state.board[fromLaneIndex].items.splice(fromCardIndex, 1);
      state.board[toLaneIndex].items.push(card);
/*      state.board[toLaneIndex].items.sort((a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      })*/
    }
  }
}

function updateCurrentPageInBoard(state) {
  if (state.board.length > 0) {
    for (let i = 0; i < state.board.length; i++) {
      let count = state.board[i].items?.length || 0;

      state.board[i].currentPage = Math.floor(count/APPLICATIONS_BOARD_PAGE_SIZE) || 1
    }
  }
}

export const {clear, moveCard, changeDescription, changeState, changeNotice, changeEmployee,
  updateEmployees, updateStates, updateTasks, updateSubjects, updateTaskTypes, updateMessage,
  removeApplication, addApplication} = applicationSlice.actions;

export default applicationSlice.reducer