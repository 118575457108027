import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authSlice';
import userReducer from './reducers/userSlice';
import employeeReducer from "./reducers/employeeSlice";
import applicationStateReducer from "./reducers/applicationStateSlice";
import counterpartyReducer from "./reducers/counterpartySlice";
import companyReducer from "./reducers/companySlice";
import applicationReducer from "./reducers/applicationSlice";
import taskTypeReducer from "./reducers/taskTypeSlice";
import subjectReducer from "./reducers/subjectSlice";
import ApiService from "../service/ApiService";

const reducer = {
  auth: authReducer,
  user: userReducer,
  employee: employeeReducer,
  applicationState: applicationStateReducer,
  counterparty: counterpartyReducer,
  company: companyReducer,
  application: applicationReducer,
  taskType: taskTypeReducer,
  subject: subjectReducer
}

let preloadedState = {};

try {
  preloadedState = JSON.parse(localStorage["store"]);
} catch (e) {

}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState
})

store.subscribe(() => {
  localStorage['store'] = JSON.stringify({auth: store.getState().auth});
})

ApiService.setDispatch(store.dispatch);

export default store;