// @flow
import {createSlice} from "@reduxjs/toolkit";
import {createCRUDActionsAsyncThunk, createDefaultAsyncThunk} from "../utils/commonAsynkThunk";
import {createCRUDActionBuilder, createDefaultActionBuilder} from "../utils/commonActionBuilder";

const entityName = "taskType";

export const getPageableList = createDefaultAsyncThunk(`${entityName}:list`, `${entityName}:list`);
export const CRUDActions = createCRUDActionsAsyncThunk(entityName);

export const taskTypeSlice = createSlice({
  name: entityName,
  initialState: {
    item: null,
    list: null,
    loadingCount: 0,
    isLoading: false
  },
  reducers: {
    clear(state) {
      state.item = null;
      state.list = null;
      state.listAll = null;
      state.isLoading = false;
      state.loadingCount = 0;
    }
  },
  extraReducers: (builder) => {
    createDefaultActionBuilder(builder, getPageableList, 'list');
    createCRUDActionBuilder(builder, CRUDActions, {
      create: "Тип работы был успешно создан",
      delete: "Тип работы был успешно удален",
      update: "Тип работы был успешно обновлен"
    })
  }
})

export const {clear} = taskTypeSlice.actions;

export default taskTypeSlice.reducer