import React from 'react';
import {Typography} from "antd";
import {CRUDActions, getPageableList} from "../../store/reducers/subjectSlice";
import DataListPage from "../DataListPage/DataListPage";

const { Title } = Typography;

export default function SubjectListPage() {
  let options = {
    entityName: 'subject',
    actions: {
      CRUDActions,
      getPageableList
    },
    limit: 20,
    canCreate: true,
    deleteText: "Вы действительно хотите удалить предмет ",
    deleteField: "name",
    columns: [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
      }
    ]
  };

  return (
    <>
      <Title level={3}>Предметы</Title>

      <DataListPage {...options} />
    </>
  )
}