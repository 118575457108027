import React, {useEffect} from 'react';
import {Button, Form, Input} from "antd";
import './LoginForm.scss'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {getToken} from "../../store/reducers/authSlice";
import {useHistory} from "react-router-dom";

export default function LoginForm() {
  const {token, isLoading} = useSelector(state => state?.auth ? state.auth: {})
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    dispatch(getToken(values));
  }

  useEffect(() => {
    if (token) {
      history.push("/applications/board");
    }
  }, [token, history])

  return (
    <div className='login-form__wrapper'>
      <Form
        name="normal_login"
        className="login-form__form"
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              pattern: /.*@.*/,
              message: 'Укажите ваш email',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Укажите ваш пароль',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form__button" loading={!!isLoading}>
            {isLoading ? 'Загрузка': 'Войти'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}