export const getRoleName = (role) => {
  let roleName = '';

  switch (role) {
    case 'ROLE_ADMIN':
      roleName = 'Администратор';
      break;

    case 'ROLE_MANAGER':
      roleName = 'Менеджер';
      break;

    case 'ROLE_SENIOR_MANAGER':
      roleName = 'Руководитель менеджеров';
      break;

    default:
      roleName = 'Пользователь';
      break;
  }

  return roleName;
}

export const getRoleColor = (role) => {
  let roleName = '';

  switch (role) {
    case 'ROLE_ADMIN':
      roleName = 'red';
      break;

    case 'ROLE_MANAGER':
      roleName = 'blue';
      break;

    case 'ROLE_SENIOR_MANAGER':
      roleName = 'green';
      break;

    default:
      roleName = 'green';
      break;
  }

  return roleName;
}

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

export const formatEmployeeName = employee => {
  let first = employee.name?.substr(0, 1) + ".";
  let second = " " + employee?.middlename?.substr(0, 1) + ".";
  let third = " " + employee?.surname;

  return first + (employee?.middlename ? second: "") + third;
}