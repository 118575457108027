// @flow
import {createSlice} from "@reduxjs/toolkit";
import {createActionAsyncThunk, createCRUDActionsAsyncThunk, createDefaultAsyncThunk} from "../utils/commonAsynkThunk";
import {createCRUDActionBuilder, createDefaultActionBuilder} from "../utils/commonActionBuilder";

const entityName = "company";

export const getPageableList = createDefaultAsyncThunk(`${entityName}:list`, `${entityName}:list`);
export const CRUDActions = createCRUDActionsAsyncThunk(entityName);
export const CRUDChannelActions = createCRUDActionsAsyncThunk(entityName, '-channels');
export const addChannel = createActionAsyncThunk(entityName, "add-channel");

export const companySlice = createSlice({
  name: entityName,
  initialState: {
    item: null,
    list: null,
    loadingCount: 0,
    isLoading: false
  },
  reducers: {
    clear(state) {
      state.item = null;
      state.list = null;
      state.isLoading = false;
      state.loadingCount = 0;
    }
  },
  extraReducers: (builder) => {
    createDefaultActionBuilder(builder, getPageableList, 'list');
    createCRUDActionBuilder(builder, CRUDActions, {
      create: "Компания была успешно создана",
      delete: "Компания была успешно удалена",
      update: "Компания была успешно обновлена"
    });
    createCRUDActionBuilder(builder, CRUDChannelActions, {
      create: "Канал связи был успешно создан",
      delete: "Канал был успешно удален",
      update: "Канал был успешно обновлен"
    });
    createDefaultActionBuilder(builder, addChannel);
  }
})

export const {clear} = companySlice.actions;

export default companySlice.reducer