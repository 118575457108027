import React from 'react';
import {Tag, Typography} from "antd";
import {getPageableList, CRUDActions} from "../../store/reducers/userSlice";
import {getRoleColor, getRoleName} from "../../utils/stringHelper";
import {Link} from "react-router-dom";
import DataListPage from "../DataListPage/DataListPage";

const { Title } = Typography;

export default function UserListPage() {
  let options = {
    entityName: 'user',
    actions: {
      CRUDActions,
      getPageableList
    },
    limit: 20,
    canCreate: true,
    deleteText: "Вы действительно хотите удалить сотрудника ",
    deleteField: "surname",
    columns: [
      {
        title: 'Email',
        dataIndex: '',
        key: 'email',
        sorter: true,
        render: item => <Link to={"/user/" + item.id}>{item.email}</Link>
      },
      {
        title: 'Роли',
        dataIndex: 'roles',
        key: 'roles',
        render: roles => (
          <span>
          {roles.map(role => (
            <Tag color={getRoleColor(role)} key={role}>
              {getRoleName(role)}
            </Tag>
          ))}
        </span>
        )
      }
    ]
  };

  return (
    <>
      <Title level={3}>Пользователи</Title>

      <DataListPage {...options} />
    </>
  )
}