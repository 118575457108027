import React, {useEffect, useRef, useState} from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Comment,
  Descriptions,
  Form, Input,
  List,
  Row,
  Select, Spin,
  Switch,
  Typography, Upload
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import HalResource from "../../service/HalResource";
import CommonAction from "../../utils/commonAction";
import {
  addMessage,
  CRUDActions,
  getMessages,
  setEmployee, setNotice, setState, setDescription
} from "../../store/reducers/applicationSlice";
import {
  UserOutlined,
  MessageOutlined,
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import './ApplicationPage.scss';
import moment from "moment";
import TasksEditableTable from "../TasksEditableTable/TasksEditableTable";
import {IS_SPAM_STATE_ID, API_SERVER_HOST} from "../../utils/constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MessageEditor from "../MessageEditor/MessageEditor";
import {addIsViewedApplication} from "../../store/reducers/authSlice";
import {formatEmployeeName} from "../../utils/stringHelper";
import ApiService from "../../service/ApiService";

const { Title } = Typography;

export default function ApplicationPage() {
  let { id } = useParams();

  const {isLoading, item, messages, states, employees} = useSelector(state => state?.application);
  const {isViewedApplications} = useSelector(state => state?.auth ? state?.auth : {});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formWithDescription] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const messageListRef = useRef(null);
  const token = ApiService?.getToken();

  let dataResource = null;

  if (item) {
    dataResource = new HalResource(item);
  }

  if (dataResource && id && !isSaving) {
    formWithDescription?.setFieldsValue({
      description: dataResource?.getProp("description"),
    });

    form?.setFieldsValue({
      is_spam: dataResource?.getProp("state")?.id === 10,
      application_state: dataResource?.getProp("state")?.id,
      employee_id: dataResource?.getProp("employee")?.id,
      notice_id: dataResource?.getProp("notice")?.id,
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(CRUDActions({
        action: CommonAction.READ,
        id,
        pipe: [
          resource => resource?.hasLink('prop:messages') ?
            getMessages({resource, onSuccess: () => scrollToBottom()}) : null,
        ]
      }))

      if (isViewedApplications?.indexOf(id) < 0) {
        dispatch(addIsViewedApplication({uid: id}))
      }
    }
  }, [id])

  const onChangeEmployee = (value) => {
    setIsSaving(true);

    dispatch(setEmployee({
      resource: dataResource,
      body: {
        employee_id: value
      },
      pipe: () => CRUDActions({action: CommonAction.READ, id,
        callback: () => {
          setIsSaving(false);
        }}),
    }))
  }

  const onChangeState = (value) => {
    setIsSaving(true);

    dispatch(setState({
      resource: dataResource,
      body: {
        state_id: value
      },
      pipe: () => CRUDActions({action: CommonAction.READ, id,
        callback: () => {
          setIsSaving(false);
        }}),
    }))
  }

  const onChangeNotice = (value) => {
    setIsSaving(true);

    dispatch(setNotice({
      resource: dataResource,
      body: {
        notice_id: value
      },
      pipe: () => CRUDActions({action: CommonAction.READ, id,
        callback: () => {
          setIsSaving(false);
        }}),
    }))
  }

  const onFinish = (values) => {
    setIsSaving(true);

    dispatch(setDescription({
      resource: dataResource,
      body: values,
      pipe: () => CRUDActions({
        action: CommonAction.READ,
        id,
        callback: () => {
          setIsSaving(false);
        }}),
    }));
  }

  const scrollToBottom = () => {
    let interval = setInterval(() => {
      let commentsContainer = document.querySelector('.comments .ant-spin-nested-loading');

      if (commentsContainer) {
        commentsContainer.scrollTop = commentsContainer.scrollHeight;
        clearInterval(interval)
      }
    }, 100)
  }

  window.scrollToBottom = scrollToBottom;

  let employee = dataResource?.getProp('employee');

  return (
    <div className={'application-page'}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to="/applications">Заявки</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Заявка {dataResource?.getProp('id').uid}</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={3} style={{marginTop: ".7em"}}>Заявка {dataResource?.getProp('id').uid}</Title>

      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col span={8}>
            <Descriptions title="Контрагент" bordered style={{marginTop: "25px"}}>
              <Descriptions.Item label="Имя" span={25}>{dataResource?.getProp('counterparty')?.name}</Descriptions.Item>
              <Descriptions.Item label="Телефон" span={25}>{dataResource?.getProp('counterparty')?.phone}</Descriptions.Item>
              <Descriptions.Item label="Email" span={25}>{dataResource?.getProp('counterparty')?.email}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Канал связи" bordered style={{margin: "15px 0 25px"}}>
              <Descriptions.Item label="Компания" span={25}>{dataResource?.getProp('company_channel')?.company?.name}</Descriptions.Item>
              <Descriptions.Item label="Название" span={25}>{dataResource?.getProp('company_channel')?.name}</Descriptions.Item>
            </Descriptions>
          </Col>

          <Col span={8}>
            <Form
              style={{marginTop: "30px"}}
              form={formWithDescription}
              layout={'vertical'}
              className="ant-advanced-search-form"
              onFinish={onFinish}
            >
              <Form.Item
                name='description'
                label='Описание'
              >
                <Input.TextArea placeholder={'Описание'} rows={8} style={{height: '175px'}} disabled={!dataResource?.hasLink('action:set-description')}/>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={!dataResource?.hasLink('action:set-description')}>Сохранить</Button>
              </Form.Item>
            </Form>
          </Col>

          <Col span={8}>
            <Form
              style={{marginTop: "30px"}}
              layout={'vertical'}
              form={form}
              className="ant-advanced-search-form"
            >
              <Form.Item
                name='employee_id'
                label='Ответственный'
              >
                {employees && dataResource?.hasLink('action:set-employee') && (
                  <Select placeholder="Выберите ответственного"
                          value={dataResource?.getProp("employee")?.id}
                          onChange={value => onChangeEmployee(value)}>
                    {employees.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {formatEmployeeName(item)}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                {employee && !dataResource?.hasLink('action:set-employee') && (
                  <Input value={formatEmployeeName(employee)} disabled/>
                )}
              </Form.Item>

              <Form.Item
                name='application_state'
                label='Статус'
              >
                {states && dataResource?.hasLink('action:set-state') && (
                  <Select placeholder="Выберите статус"
                          value={dataResource?.getProp("state")?.id}
                          onChange={value => onChangeState(value)}>
                    {states.map(item => (
                      <Select.Option key={item.id} value={item.id} style={{backgroundColor: item.color}}>{item.name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              {dataResource?.getProp("state")?.notices?.length > 0 && (
                <Form.Item
                  name='notice_id'
                  label='Примечание'
                >
                  {dataResource?.getProp("state")?.notices && dataResource?.hasLink('action:set-notice') && (
                    <Select placeholder="Выберите примечание"
                            value={dataResource?.getProp("notice")?.id}
                            onChange={value => onChangeNotice(value)}>
                      {dataResource?.getProp("state")?.notices.map(item => (
                        item.is_active && <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}

              <Form.Item
                name='is_spam'
                label='Это спам?'
                valuePropName={'checked'}
              >
                {dataResource?.hasLink('action:set-state') && (
                  <Switch checkedChildren={"Спам"} unCheckedChildren={"Не спам"}
                        checked={dataResource?.getProp("state")?.id === 10}
                        onChange={value => {
                          if (value) {
                            onChangeState(IS_SPAM_STATE_ID);
                          } else {
                            onChangeState(1);
                          }
                        }} />
                )}
              </Form.Item>

            </Form>
          </Col>
        </Row>

        {dataResource?.hasLink('prop:tasks') && (
          <TasksEditableTable applicationResource={dataResource}/>
        )}

        {messages?.length && dataResource?.hasLink('prop:messages') ? (
          <>
            <List
              className="comments"
              header={`${messages.length} сообщений`}
              itemLayout="horizontal"
              dataSource={messages}
              ref={messageListRef}
              rowKey={item => item.id}
              renderItem={item => {
                let content = <>
                  <div dangerouslySetInnerHTML={{__html: item.text}}/>
                  {item.attachments && item.attachments.length > 0 ? (
                    <Upload defaultFileList={
                      item.attachments?.map(i => ({
                        uid: i.part_index,
                        name: i.name,
                        status: 'done',
                        url: `${API_SERVER_HOST}/api/messages/${item.id?.uid}/attachment/${i.part_index}?token=${token?.token}`,
                      }))} showUploadList={{
                      showDownloadIcon: true,
                      showRemoveIcon: false
                    }}/>
                  ) : null}
                </>

                return (
                  item.is_user_message ? (
                    <li className="comment comment--user">
                      <Comment
                        author={<span className="comment__author">Контрагент {dataResource?.getProp('counterparty')?.name}</span>}
                        avatar={<UserOutlined />}
                        content={content}
                        datetime={<span className="comment__date">{moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}</span>}
                      />
                    </li>
                  ) : (
                    <li className="comment">
                      <Comment
                        author={
                          <>
                            <span className="comment__author">Менеджер {formatEmployeeName(item.employee)}</span>
                            <span style={{display: 'inline-block', marginLeft: '8px'}}>
                              {item.is_sended ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              ) : (
                                <WarningTwoTone twoToneColor="#FF603B" />
                              )}
                            </span>
                          </>}
                        avatar={<MessageOutlined />}
                        content={content}
                        datetime={<span className="comment__date">{moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}</span>}
                      />
                    </li>
                  )
                )
              }}
            />
          </>
        ) : null}

        {dataResource?.hasLink('action:add-message') && (
          <MessageEditor onSendMessage={(value) => {
            dispatch(addMessage({
              resource: dataResource,
              body: {
                text: value
              },
              pipe: () => getMessages({resource: dataResource, onSuccess: () => scrollToBottom()}),
            }))
          }} />
        )}
      </Spin>
    </div>
  )
}