import {MERCURE_SERVER_HOST, MercureHubEvents} from "./constants";
import {notification} from "antd";
import {
  addApplication,
  changeDescription,
  changeEmployee,
  changeNotice,
  changeState, removeApplication, updateEmployees, updateMessage,
  updateStates, updateSubjects, updateTasks, updateTaskTypes
} from "../store/reducers/applicationSlice";
import store from "../store/store";
import {removeIsViewedApplication} from "../store/reducers/authSlice";
import { EventSourcePolyfill } from 'event-source-polyfill';

export class MercureHub {
  static #dispatch = null;
  static #sse = null;

  static init = (dispatch) => {
    this.#dispatch = dispatch;

    let url = new URL(MERCURE_SERVER_HOST + '/.well-known/mercure');

    url.searchParams.append('topic', MercureHubEvents.HEARTBEAT);

    url.searchParams.append('topic', MercureHubEvents.APPLICATION_STATE_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_DESCRIPTION_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_NOTICE_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_EMPLOYEE_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_MESSAGE_ADDED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_TASKS_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_ADDED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_DELETED);
    url.searchParams.append('topic', MercureHubEvents.APPLICATION_STATES_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.EMPLOYEES_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.TASK_TYPES_UPDATED);
    url.searchParams.append('topic', MercureHubEvents.SUBJECTS_UPDATED);

    this.#sse = new EventSourcePolyfill(url, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_MERCURE_TOKEN,
      }
    });

    this.#sse.onmessage = e => this.#handleEvent(e);
    this.#sse.onerror = (e) => {
      notification.error({
        message: 'Соединение с сервером потеряно',
        description: 'Пожалуйста, перезагрузите страницу, если ошибка повторяется, обратитесь в техподдержку',
      });
    }
  }

  static close = () => {
    this.#sse?.close();
  }

  static #handleEvent= (event) => {
    let data = JSON.parse(event.data)

    console.log(data);
    switch (data.type) {
      case MercureHubEvents.APPLICATION_DESCRIPTION_UPDATED:
        this.#dispatch(changeDescription(data));
        break;
      case MercureHubEvents.APPLICATION_STATE_UPDATED:
        this.#dispatch(changeState(data));
        break;
      case MercureHubEvents.APPLICATION_NOTICE_UPDATED:
        this.#dispatch(changeNotice(data));
        break;
      case MercureHubEvents.APPLICATION_EMPLOYEE_UPDATED: {
        let {user} = store.getState().auth;

        this.#dispatch(changeEmployee({...data, user}));
        this.#dispatch(removeIsViewedApplication({uid: data?.application?.id?.uid}))
        break;
      }
      case MercureHubEvents.APPLICATION_MESSAGE_ADDED:
        this.#dispatch(updateMessage(data));
        this.#dispatch(removeIsViewedApplication({uid: data?.applicationId}))
        break;
      case MercureHubEvents.APPLICATION_TASKS_UPDATED: {
        let {user} = store.getState().auth;

        this.#dispatch(updateTasks({...data, user}));
        this.#dispatch(removeIsViewedApplication({uid: data?.application?.id?.uid}))
        break;
      }
      case MercureHubEvents.APPLICATION_ADDED:
        let {user} = store.getState().auth;

        this.#dispatch(addApplication({...data, user}));
        this.#dispatch(removeIsViewedApplication({uid: data?.application?.id?.uid}))
        break;
      case MercureHubEvents.APPLICATION_DELETED:
        this.#dispatch(removeApplication(data));
        break;

      case MercureHubEvents.APPLICATION_STATES_UPDATED:
        this.#dispatch(updateStates(data));
        break;
      case MercureHubEvents.EMPLOYEES_UPDATED:
        this.#dispatch(updateEmployees(data));
        break;
      case MercureHubEvents.SUBJECTS_UPDATED:
        this.#dispatch(updateSubjects(data));
        break;
      case MercureHubEvents.TASK_TYPES_UPDATED:
        this.#dispatch(updateTaskTypes(data));
        break;
    }
  }
}