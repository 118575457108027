import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../store/reducers/authSlice";
import ApiService from "../../service/ApiService";
import {MercureHub} from "../../utils/MercureHub";
import {
  getAllEmployees,
  getAllStates,
  getAllSubjects,
  getAllTaskTypes,
  getBoard
} from "../../store/reducers/applicationSlice";

export default function InitComponent({children}) {
  const dispatch = useDispatch();
  const [baseResource, setBaseResource] = useState(null);

  useEffect(() => {
    MercureHub.init(dispatch);

    (async () => {
      let baseResource = await ApiService.getBaseResource();

      dispatch(getUser());

      if (baseResource?.hasLink('applicationState:list')) {
        dispatch(getAllStates({
          is_active_only: true
        }))
      }

      if (baseResource?.hasLink('subject:list')) {
        dispatch(getAllSubjects());
      }

      if (baseResource?.hasLink('taskType:list')) {
        dispatch(getAllTaskTypes());
      }

      if (baseResource?.hasLink('taskType:list')) {
        dispatch(getAllEmployees());
      }

      dispatch(getBoard());

      setBaseResource(baseResource);
    })()

    return () => {
      MercureHub.close?.();
    };
  }, []);

  return children;
}