import React from 'react';
import {Typography} from "antd";
import {CRUDActions, getPageableList} from "../../store/reducers/counterpartySlice";
import DataListPage from "../DataListPage/DataListPage";

const { Title } = Typography;

export default function CounterpartyListPage() {
  let options = {
    entityName: 'counterparty',
    actions: {
      CRUDActions,
      getPageableList
    },
    limit: 20,
    canCreate: true,
    deleteText: "Вы действительно хотите удалить контрагента ",
    deleteField: "name",
    columns: [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone'
      }
    ]
  };

  return (
    <>
      <Title level={3}>Контрагенты</Title>

      <DataListPage {...options} />
    </>
  )
}