import React from 'react';
import {Typography} from "antd";
import {CRUDActions, getPageableList} from "../../store/reducers/applicationStateSlice";
import DataListPage from "../DataListPage/DataListPage";

const { Title } = Typography;

export default function ApplicationStateListPage() {
  let options = {
    entityName: 'applicationState',
    actions: {
      CRUDActions,
      getPageableList
    },
    limit: 20,
    canCreate: true,
    deleteText: "Вы действительно хотите удалить статус заявки ",
    deleteField: "name",
    columns: [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Цвет',
        dataIndex: 'color',
        key: 'color',
        render: (item) => <div style={{width: '50px', height: '20px', backgroundColor: item}} />
      }
    ]
  };

  return (
    <>
      <Title level={3}>Статусы</Title>

      <DataListPage {...options} />
    </>
  )
}