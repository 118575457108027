import {clearAuthState} from "../reducers/authSlice";
import {clear as clearUserState} from "../reducers/userSlice";
import {clear as clearEmployeeState} from "../reducers/employeeSlice";
import {clear as clearApplicationStateState} from "../reducers/applicationStateSlice";
import {clear as clearCounterpartyState} from "../reducers/counterpartySlice";
import {clear as clearApplicationState} from "../reducers/applicationSlice";
import {message} from "antd";

export const logout = (dispatch) => {
  dispatch(clearAuthState());
  dispatch(clearUserState());
  dispatch(clearEmployeeState());
  dispatch(clearApplicationStateState());
  dispatch(clearCounterpartyState());
  dispatch(clearApplicationState());

  message.success("Вы успешно вышли из аккаунта");
}